import React, { useState, useEffect } from 'react'
// material
import {
  Link,
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  Switch,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Dialog,
} from '@mui/material'
import Moment from 'moment'
// redux
import { RootState, useDispatch, useSelector } from '../../redux/store'

import { Link as RouterLink } from 'react-router-dom'
import Scrollbar from '../../components/Scrollbar'
import DeleteIcon from '@mui/icons-material/Delete'
import { PATH_DASHBOARD } from '../../routes/paths'
import styles from './ArticlesTable.module.css'
import { useSnackbar } from 'notistack'
import { ArticlesItem } from '../../@types/articles'
import {
  changeActiveArticlesItemThunk,
  changeShowMainArticlesItemThunk,
  deleteArticlesItemThunk,
  getArticlesListThunk,
} from '../../redux/thunks/articles'

export default function ArticlesTable() {
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const { articlesList } = useSelector((state: RootState) => state.articles)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [shownArticles, setShownArticles] = useState(0)

  const [openDeleteConfirm, setOpenDeleteConfirm] = useState<
    ArticlesItem | undefined
  >(undefined)

  useEffect(() => {
    dispatch(getArticlesListThunk())
  }, [dispatch])

  useEffect(() => {
    setShownArticles(articlesList.filter(item => item.showOnMain).length)
  }, [articlesList])

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 20))
    setPage(0)
  }

  const handleDelete = (item: ArticlesItem) => {
    setOpenDeleteConfirm(item)
  }

  const handleDeleteClose = async (confirm: boolean) => {
    const id = openDeleteConfirm?.id
    setOpenDeleteConfirm(undefined)
    if (confirm) {
      const result = await dispatch(deleteArticlesItemThunk('' + id))
      if (result) {
        enqueueSnackbar('Статья удалена', { variant: 'success' })
      } else {
        enqueueSnackbar('Ошибка удаления статьи', { variant: 'error' })
      }
    }
  }

  const handleActiveChange = async (item: ArticlesItem, checked: boolean) => {
    const result = await dispatch(
      changeActiveArticlesItemThunk('' + item.id, checked),
    )
    if (result) {
      enqueueSnackbar('Активность статьи изменена', { variant: 'success' })
    } else {
      enqueueSnackbar(
        'Ошибка выполнения запроса на изменение активности статьи',
        { variant: 'error' },
      )
    }
  }
  const handleShowMainChange = async (item: ArticlesItem, checked: boolean) => {
    const result = await dispatch(
      changeShowMainArticlesItemThunk('' + item.id, checked),
    )
    if (result) {
      enqueueSnackbar('Отображение статьи изменено', { variant: 'success' })
    } else {
      enqueueSnackbar(
        'Ошибка выполнения запроса на изменение активности статьи',
        { variant: 'error' },
      )
    }
  }

  return (
    <>
      <Dialog
        open={openDeleteConfirm !== undefined}
        onClose={() => handleDeleteClose(false)}
      >
        <DialogTitle>Удалить статью?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Вы действительно хотите удалить статью "{openDeleteConfirm?.title}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDeleteClose(false)} autoFocus>
            Отмена
          </Button>
          <Button onClick={() => handleDeleteClose(true)}>Подтвердить</Button>
        </DialogActions>
      </Dialog>

      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Заголовок</TableCell>
                  <TableCell>Дата</TableCell>
                  <TableCell align="right">Активность</TableCell>
                  <TableCell align="right">Выводить на главную</TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {articlesList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(row => {
                    const { id, title, date, active, staticPath, showOnMain } =
                      row

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox">
                        <TableCell component="th" scope="row" padding="none">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Link
                              underline="none"
                              className={`${styles.labelLink}`}
                              component={RouterLink}
                              to={`${PATH_DASHBOARD.articles.root}/${staticPath}/edit`}
                            >
                              <Typography variant="subtitle2" noWrap>
                                {title}
                              </Typography>
                            </Link>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">
                          {Moment(date).format('YYYY-MM-DD')}
                        </TableCell>
                        <TableCell align="right">
                          <Switch
                            checked={!!active}
                            onChange={(e, checked) =>
                              handleActiveChange(row, checked)
                            }
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Switch
                            checked={!!showOnMain}
                            disabled={shownArticles >= 3 && !!!showOnMain}
                            onChange={(e, checked) =>
                              handleShowMainChange(row, checked)
                            }
                          />
                        </TableCell>
                        <TableCell align="right">
                          <div onClick={e => handleDelete(row)}>
                            <DeleteIcon sx={{ cursor: 'pointer' }} />
                          </div>
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={articlesList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, page) => setPage(page)}
          onRowsPerPageChange={e => handleChangeRowsPerPage}
        />
      </Card>
    </>
  )
}
