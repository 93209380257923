import {
  Button,
  Card,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useCallback, useEffect, useState } from 'react'
import { Icon } from '@iconify/react'
import plusFill from '@iconify/icons-eva/plus-fill'
import { UploadSingleFile } from 'components/upload'
import { dispatch } from 'redux/store'
import { uploadFileThunk } from 'redux/thunks/files'
import { useSnackbar } from 'notistack'
import axios, { baseUrl } from 'utils/axios'
import DeleteIcon from '@mui/icons-material/Delete'
import { LoadingButton } from '@mui/lab'

interface Slide {
  id: number | null
  title: string
  description: string
  file: localFile | null
  preview: localFile | null
  public: {
    preview: localFile | null
  }
  position: number
  active: boolean
}

interface localFile extends File {
  fileName: string
  file: string
}

export default function MainSlider() {
  const [slides, setSlides] = useState<Slide[]>([])
  const { enqueueSnackbar } = useSnackbar()

  const handleDropFile = useCallback(
    async (acceptedFiles, caseIndex, key: 'file' | 'preview') => {
      const file = acceptedFiles[0]
      if (file) {
        const loadedFileName = await dispatch(uploadFileThunk(file))
        if (loadedFileName) {
          setSlides(prev =>
            prev.map((item, idx) => {
              if (idx === caseIndex) {
                return {
                  ...item,
                  [key]: {
                    ...file,
                    fileName: loadedFileName,
                    preview: URL.createObjectURL(file),
                  },
                }
              }
              return item
            }),
          )
        } else {
          enqueueSnackbar('Ошибка загрузки файла', { variant: 'error' })
        }
      }
    },
    [],
  )

  const getSlides = async () => {
    const data = await axios.get('/sliders')
    setSlides(
      data.data.map((item: Slide) => {
        return {
          ...item,
          file: {
            ...item.file,
            fileName: item.file?.file,
            preview: `${baseUrl}/files/sliders/${item.file?.file}`,
          },
          public: {
            preview: {
              ...item.public.preview,
              fileName: item.public.preview?.file,
              preview: `${baseUrl}/files/sliders/${item.public.preview?.file}`,
            },
          },
        }
      }),
    )
  }

  const putSlides = async () => {
    try {
      const data = await axios.put('/sliders', {
        sliders: slides.map(slide => {
          return {
            ...slide,
            file: slide.file?.fileName,
            preview: slide.preview?.fileName ?? slide.public.preview?.file,
          }
        }),
      })
      setSlides(
        data.data.map((item: Slide) => {
          return {
            ...item,
            file: {
              ...item.file,
              fileName: item.file?.file,
              preview: `${baseUrl}/files/sliders/${item.file?.file}`,
            },
            public: {
              preview: {
                ...item.public.preview,
                fileName: item.public.preview?.file,
                preview: `${baseUrl}/files/sliders/${item.public.preview?.file}`,
              },
            },
          }
        }),
      )
      enqueueSnackbar('Сохранено', { variant: 'success' })
    } catch (err) {
      enqueueSnackbar('При сохранении возникла ошибка', { variant: 'error' })
    }
  }

  const addCase = () => {
    setSlides(prev => [
      ...prev,
      {
        id: null,
        title: '',
        description: '',
        file: null,
        preview: null,
        public: {
          preview: null,
        },
        position: 1,
        active: false,
      },
    ])
  }

  const removeSlide = (index: number) => {
    setSlides(prev => {
      if (prev.length <= 1) {
        return []
      }
      return [...prev.slice(0, index), ...prev.slice(index + 1, prev.length)]
    })
  }

  const changeCase = (index: number, field: string, value: unknown) => {
    setSlides(prev =>
      prev.map((item: any, idx) => {
        if (idx === index) {
          return {
            ...item,
            [field]: value,
          }
        }
        return item
      }),
    )
  }

  useEffect(() => {
    getSlides()
  }, [])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5, mt: 2 }}>
      <Button
        sx={{ width: 'fit-content' }}
        variant="contained"
        startIcon={<Icon icon={plusFill} />}
        onClick={addCase}
      >
        Добавить слайд
      </Button>

      {slides.map((item, index) => (
        <Card sx={{ p: 3 }} key={index}>
          <FormControlLabel
            labelPlacement="start"
            control={
              <Switch
                checked={!!item.active}
                onChange={e => changeCase(index, 'active', !!!item.active)}
              />
            }
            label={
              <>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Активность
                </Typography>
              </>
            }
            sx={{
              mx: 0,
              mb: 3,
              width: 'fit-content',
              justifyContent: 'space-between',
            }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              mt: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 10,
                mt: 2,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: '65%',
                  flexDirection: 'column',
                  gap: 2,
                  mt: 2,
                }}
              >
                <TextField
                  label="Название слайда"
                  value={item.title}
                  onChange={e => changeCase(index, 'title', e.target.value)}
                ></TextField>
                <TextField
                  label="Описание слайда"
                  value={item.description}
                  onChange={e =>
                    changeCase(index, 'description', e.target.value)
                  }
                ></TextField>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  mt: 2,
                }}
              >
                <UploadSingleFile
                  file={item.file}
                  onDrop={acceptedFiles =>
                    handleDropFile(acceptedFiles, index, 'file')
                  }
                />

                <UploadSingleFile
                  file={item.public.preview}
                  onDrop={acceptedFiles =>
                    handleDropFile(acceptedFiles, index, 'preview')
                  }
                />
              </Box>
              <div onClick={() => removeSlide(index)}>
                <DeleteIcon sx={{ cursor: 'pointer' }} />
              </div>
            </Box>
          </Box>
        </Card>
      ))}

      <LoadingButton
        sx={{ width: 'fit-content' }}
        variant="contained"
        onClick={putSlides}
      >
        Сохранить
      </LoadingButton>
    </Box>
  )
}
