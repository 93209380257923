import { createSlice } from '@reduxjs/toolkit'

// ----------------------------------------------------------------------

export interface IStockInfo {
  id: number
  title: string
  stockId: number
  active: number
  stock: {
    id: number
    title: string
  }
}

type TagState = {
  isLoading: boolean
  error: boolean
  stocks: IStockInfo[]
}

const initialState: TagState = {
  isLoading: false,
  error: false,
  stocks: [],
}

const slice = createSlice({
  name: 'stock',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    getStocks(state, action) {
      state.stocks = action.payload
    },
  },
})

export const { startLoading, hasError, getStocks } = slice.actions

export default slice.reducer
