import { createSlice } from '@reduxjs/toolkit'
import { AdsItem, FullAdsItem } from '../../@types/ads'

// ----------------------------------------------------------------------

type AdsState = {
  isLoading: boolean
  error: boolean
  adsList: AdsItem[]
  currentAdsItem?: FullAdsItem
}

const initialState: AdsState = {
  isLoading: false,
  error: false,
  adsList: [],
}

const slice = createSlice({
  name: 'ads',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    getAdsListSuccess(state, action) {
      state.isLoading = false
      state.adsList = action.payload
    },

    getAdsItemSuccess(state, action) {
      state.isLoading = false
      state.currentAdsItem = action.payload
    },

    changeAdsItemActiveStatus(state, action) {
      const list = state.adsList.map(item => {
        const active =
          item.id !== action.payload ? item.active : item.active ? 0 : 1
        return {
          ...item,
          active,
        }
      })
      state.adsList = list
    },

    deleteAdsItem(state, action) {
      const list = state.adsList.filter(item => item.id !== action.payload)
      state.adsList = list
    },

    updateAdsSortOrder(state, action) {
      const sort: number[] = action.payload
      const list: AdsItem[] = []
      sort.forEach(value => {
        const item = state.adsList.find(item => item.id === value)
        if (item !== undefined) list.push(item)
      })
      state.adsList = list
    },
  },
})

export const {
  startLoading,
  hasError,
  getAdsListSuccess,
  getAdsItemSuccess,
  changeAdsItemActiveStatus,
  deleteAdsItem,
  updateAdsSortOrder,
} = slice.actions

export default slice.reducer
