import { Button, Card, TextField } from '@mui/material'
import { Box } from '@mui/system'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useCallback, useEffect, useState } from 'react'
import { Icon } from '@iconify/react'
import plusFill from '@iconify/icons-eva/plus-fill'
import { UploadMultiFile, UploadSingleFile } from 'components/upload'
import { dispatch } from 'redux/store'
import { uploadFileThunk } from 'redux/thunks/files'
import { useSnackbar } from 'notistack'
import { LoadingButton } from '@mui/lab'
import axios, { baseUrl } from 'utils/axios'

interface Career {
  title: string
  description: string
  files: (localFile | string)[]
}

interface localFile extends File {
  fileName: string
  file: string
}

export default function AboutCareer() {
  const [career, setCareer] = useState<Career>({
    title: '',
    description: '',
    files: ['', '', '', ''],
  })
  const { enqueueSnackbar } = useSnackbar()

  const handleDropFile = useCallback(async (acceptedFiles, idx) => {
    const file = acceptedFiles[0]
    if (file) {
      const loadedFileName = await dispatch(uploadFileThunk(file))
      if (loadedFileName) {
        setCareer(prev => ({
          ...prev,
          files: prev.files.map((careerFile, fileIdx) => {
            // console.log(career.files)
            if (fileIdx === idx) {
              return {
                ...file,
                fileName: loadedFileName,
                preview: URL.createObjectURL(file),
              }
            }
            return careerFile
          }),
        }))
      } else {
        enqueueSnackbar('Ошибка загрузки файла', { variant: 'error' })
      }
    }
  }, [])

  const changeCase = (field: string, value: unknown) => {
    setCareer(prev => ({
      ...prev,
      [field]: value,
    }))
  }

  const getCareer = async () => {
    const data = await axios.get('/blocks/career')
    const fillImagesCount = 5 - data.data.files.length
    const emptyImages = Array.from(Array(fillImagesCount), () => '')
    setCareer({
      ...data.data,
      files: [
        ...data.data.files.map((file: localFile) => {
          return {
            ...file,
            fileName: file.file,
            preview: `${baseUrl}/files/blockContainers/${file.file}`,
          }
        }),
        ...emptyImages,
      ],
    })
  }

  const putCareer = async () => {
    try {
      const data = await axios.put('/blocks/career', {
        ...career,
        files: career.files.map(file => (file as localFile).fileName),
      })
      setCareer({
        ...data.data,
        files: data.data.files.map((file: localFile) => {
          return {
            ...file,
            fileName: file.file,
            preview: `${baseUrl}/files/blockContainers/${file.file}`,
          }
        }),
      })
      enqueueSnackbar('Сохранено', { variant: 'success' })
    } catch (err) {
      enqueueSnackbar('При сохранении возникла ошибка', { variant: 'error' })
    }
  }

  useEffect(() => {
    getCareer()
  }, [])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5, mt: 2 }}>
      <Card sx={{ p: 3 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            mt: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 10,
              mt: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '65%',
                flexDirection: 'column',
                gap: 2,
                mt: 2,
              }}
            >
              <TextField
                label="Заголовок"
                value={career.title}
                onChange={e => changeCase('title', e.target.value)}
              ></TextField>
              <TextField
                label="Описание"
                value={career.description}
                onChange={e => changeCase('description', e.target.value)}
              ></TextField>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              {career.files.map((file, fileIdx) => (
                <UploadSingleFile
                  key={fileIdx}
                  sx={{ width: '100%' }}
                  file={file}
                  onDrop={acceptedFiles =>
                    handleDropFile(acceptedFiles, fileIdx)
                  }
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Card>

      <LoadingButton
        sx={{ width: 'fit-content' }}
        variant="contained"
        onClick={putCareer}
      >
        Сохранить
      </LoadingButton>
    </Box>
  )
}
