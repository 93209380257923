import { AppThunk } from '../store'
import axios from '../../utils/axios'
import {
  startLoading,
  hasError,
  getArticlesListSuccess,
  getArticlesItemSuccess,
  changeArticlesItemActiveStatus,
  deleteArticlesItem,
  changeArticlesItemShowMainStatus,
} from 'redux/slices/articles'

export const getArticlesListThunk = (): AppThunk => async dispatch => {
  dispatch(startLoading())
  try {
    const response = await axios.get('/articles')
    dispatch(getArticlesListSuccess(response.data))
  } catch (error) {
    dispatch(hasError(error))
  }
}

export const getArticlesItemThunk =
  (id: string): AppThunk =>
  async dispatch => {
    if (id === '') {
      dispatch(getArticlesItemSuccess(undefined))
    } else {
      dispatch(startLoading())
      try {
        const response = await axios.get(`/articles/${id}`)
        dispatch(getArticlesItemSuccess(response.data))
      } catch (error) {
        dispatch(hasError(error))
      }
    }
  }

export const createArticlesItemThunk =
  (params: any): AppThunk<Promise<boolean>> =>
  async dispatch => {
    try {
      await axios.post(`/articles`, params)
      return true
    } catch (error) {
      dispatch(hasError(error))
    }
    return false
  }

export const editArticlesItemThunk =
  (itemId: string, params: any): AppThunk<Promise<boolean>> =>
  async dispatch => {
    try {
      await axios.put(`/articles/${itemId}`, params)
      return true
    } catch (error) {
      dispatch(hasError(error))
    }
    return false
  }

export const deleteArticlesItemThunk =
  (itemId: string): AppThunk<Promise<boolean>> =>
  async dispatch => {
    try {
      const response = await axios.delete(`/articles/${itemId}`)
      if (response.data.result) {
        dispatch(deleteArticlesItem(itemId))
        return true
      }
    } catch (error) {
      dispatch(hasError(error))
    }
    return false
  }

export const changeActiveArticlesItemThunk =
  (itemId: string, active: boolean): AppThunk<Promise<boolean>> =>
  async dispatch => {
    try {
      const params = {
        active: active ? 1 : 0,
      }
      const response = await axios.post(`/articles/${itemId}/activate`, params)
      if (response.data.result) {
        dispatch(changeArticlesItemActiveStatus(itemId))
        return true
      }
    } catch (error) {
      dispatch(hasError(error))
    }
    return false
  }
export const changeShowMainArticlesItemThunk =
  (itemId: string, showOnMain: boolean): AppThunk<Promise<boolean>> =>
  async dispatch => {
    try {
      const params = {
        showOnMain: showOnMain ? 1 : 0,
      }
      const response = await axios.post(`/articles/${itemId}/show-main`, params)
      if (response.data.result) {
        dispatch(changeArticlesItemShowMainStatus(itemId))
        return true
      }
    } catch (error) {
      dispatch(hasError(error))
    }
    return false
  }
