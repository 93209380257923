import {
  changeServiceActiveStatus,
  deleteService,
  getServiceListNameSuccess,
  getServiceListSuccess,
  getServiceSuccess,
  hasError,
  startLoading
} from 'redux/slices/services'
import axios from '../../utils/axios'
import { AppThunk } from '../store'

export const getServiceListThunk = (): AppThunk => async dispatch => {
  dispatch(startLoading())
  try {
    const response = await axios.get('/services')
    dispatch(getServiceListSuccess(response.data))
  } catch (error) {
    dispatch(hasError(error))
  }
}

export const getServiceThunk =
  (id: number): AppThunk =>
  async dispatch => {
    if (id === 0) {
      dispatch(getServiceSuccess(undefined))
    } else {
      dispatch(startLoading())
      try {
        const response = await axios.get(`/services/${id}`)
        dispatch(getServiceSuccess(response.data))
      } catch (error) {
        dispatch(hasError(error))
      }
    }
  }

export const createServiceThunk =
  (params: any): AppThunk<Promise<{ result: boolean; id: any }>> =>
  async dispatch => {
    try {
      const response = await axios.post(`/services`, params)
      return {
        result: true,
        id: response.data.id,
      }
    } catch (error) {
      dispatch(hasError(error))
    }
    return {
      result: false,
      id: null,
    }
  }

export const editServiceThunk =
  (id: string, params: any): AppThunk<Promise<{ result: boolean; id: any }>> =>
  async dispatch => {
    try {
      const response = await axios.put(`/services/${id}`, params)
      return {
        result: true,
        id: response.data.id,
      }
    } catch (error) {
      dispatch(hasError(error))
    }
    return {
      result: false,
      id: null,
    }
  }

export const deleteServiceThunk =
  (slug: string): AppThunk<Promise<boolean>> =>
  async dispatch => {
    try {
      const response = await axios.delete(`/services/${slug}`)
      if (response.data.result) {
        dispatch(deleteService(slug))
        return true
      }
    } catch (error) {
      dispatch(hasError(error))
    }
    return false
  }

export const changeActiveServiceThunk =
  (id: number, active: boolean): AppThunk<Promise<boolean>> =>
  async dispatch => {
    try {
      const params = {
        active: active ? 1 : 0,
      }
      const response = await axios.patch(`/services/${id}/activate`, params)
      if (response.data.result) {
        dispatch(changeServiceActiveStatus(id))
        return true
      }
    } catch (error) {
      dispatch(hasError(error))
    }
    return false
  }

export const updateServiceSortThunk =
  (params: {
    sortOrder: number[]
    type: 'metal' | 'service' | 'customers'
  }): AppThunk<Promise<boolean>> =>
  async dispatch => {
    dispatch(startLoading())
    try {
      const response = await axios.post(`/services/update-sort`, params)
      if (response.data) {
        dispatch(
          getServiceListNameSuccess({ data: response.data, type: params.type }),
        )
        return true
      }
    } catch (error) {
      dispatch(hasError(error))
    }
    return false
  }
