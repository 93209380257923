import {
  Button,
  Card,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useState } from 'react'
import { Icon } from '@iconify/react'
import plusFill from '@iconify/icons-eva/plus-fill'
import { useSnackbar } from 'notistack'
import DeleteIcon from '@mui/icons-material/Delete'

interface Faq {
  question: string
  answer: string
  position: number
  active: boolean
}

export default function FAQ({
  changeBlock,
  propBlock,
}: {
  changeBlock: (val: Faq[]) => void
  propBlock: Faq[]
}) {
  const [questions, setQuestions] = useState<Faq[]>([])
  const { enqueueSnackbar } = useSnackbar()

  const addCase = () => {
    setQuestions(prev => [
      ...prev,
      {
        question: '',
        answer: '',
        position: 1,
        active: false,
      },
    ])
  }

  const removeQuestions = (index: number) => {
    setQuestions(prev => {
      if (prev.length <= 1) {
        return []
      }
      return [...prev.slice(0, index), ...prev.slice(index + 1, prev.length)]
    })
  }

  const changeQuestion = (index: number, field: string, value: unknown) => {
    setQuestions(prev =>
      prev.map((item: any, idx) => {
        if (idx === index) {
          return {
            ...item,
            [field]: value,
          }
        }
        return item
      }),
    )
  }

  useEffect(() => {
    setQuestions(propBlock)
  }, [])

  useEffect(() => {
    changeBlock(questions)
  }, [questions])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5, mt: 2 }}>
      <Button
        sx={{ width: 'fit-content' }}
        variant="contained"
        startIcon={<Icon icon={plusFill} />}
        onClick={addCase}
      >
        Добавить вопрос
      </Button>

      {questions.map((item, index) => (
        <Card sx={{ p: 3 }} key={index}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <FormControlLabel
              labelPlacement="start"
              control={
                <Switch
                  checked={!!item.active}
                  onChange={e =>
                    changeQuestion(index, 'active', !!!item.active)
                  }
                />
              }
              label={
                <>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Активность
                  </Typography>
                </>
              }
              sx={{
                mx: 0,
                mb: 3,
                width: 'fit-content',
                justifyContent: 'space-between',
              }}
            />
            <div onClick={() => removeQuestions(index)}>
              <DeleteIcon sx={{ cursor: 'pointer' }} />
            </div>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: 10,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '65%',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <TextField
                label="Вопрос"
                value={item.question}
                onChange={e =>
                  changeQuestion(index, 'question', e.target.value)
                }
              ></TextField>
              <TextField
                label="Ответ"
                value={item.answer}
                onChange={e => changeQuestion(index, 'answer', e.target.value)}
              ></TextField>
            </Box>
          </Box>
        </Card>
      ))}
    </Box>
  )
}
