import { createSlice } from '@reduxjs/toolkit'
import { Contact, FullContact, Region } from '../../@types/contact'

// ----------------------------------------------------------------------

type ContactState = {
  isLoading: boolean
  error: boolean
  regionList: Region[]
  currentRegion?: Region
  contactList: Contact[]
  currentContact?: FullContact
}

const initialState: ContactState = {
  isLoading: false,
  error: false,
  regionList: [],
  contactList: [],
}

const slice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    getRegionListSuccess(state, action) {
      state.isLoading = false
      state.regionList = action.payload
    },

    getRegionSuccess(state, action) {
      state.isLoading = false
      state.currentRegion = action.payload
    },

    changeRegionActiveStatus(state, action) {
      const list = state.regionList.map(item => {
        const active =
          item.id !== +action.payload ? item.active : item.active ? 0 : 1
        return {
          ...item,
          active,
        }
      })
      state.regionList = list
    },

    deleteRegion(state, action) {
      const list = state.regionList.filter(item => item.id !== +action.payload)
      state.regionList = list
    },

    updateRegionSortOrder(state, action) {
      const sort: number[] = action.payload
      const list: Region[] = []
      sort.forEach(value => {
        const item = state.regionList.find(item => item.id === value)
        if (item !== undefined) list.push(item)
      })
      state.regionList = list
    },

    getContactListSuccess(state, action) {
      state.isLoading = false
      state.contactList = action.payload
    },

    getContactSuccess(state, action) {
      state.isLoading = false
      state.currentContact = action.payload
    },

    changeContactActiveStatus(state, action) {
      const list = state.contactList.map(item => {
        const active =
          item.id !== +action.payload ? item.active : item.active ? 0 : 1
        return {
          ...item,
          active,
        }
      })
      state.contactList = list
    },

    deleteContact(state, action) {
      const list = state.contactList.filter(item => item.id !== +action.payload)
      state.contactList = list
    },
  },
})

export const {
  startLoading,
  hasError,
  getRegionListSuccess,
  getRegionSuccess,
  changeRegionActiveStatus,
  deleteRegion,
  updateRegionSortOrder,
  getContactListSuccess,
  getContactSuccess,
  changeContactActiveStatus,
  deleteContact,
} = slice.actions

export default slice.reducer
