import { useNavigate, useParams } from 'react-router-dom'
//store
import { useEffect } from 'react'
import { RootState, useDispatch, useSelector } from '../../../redux/store'

import DataLoadingPage from 'components/DataLoadingPage'
import { PATH_DASHBOARD } from '../../../routes/paths'
import { getServiceThunk } from '../../../redux/thunks/services'
import ServicesEdit from '../../../components/services/ServicesEdit'
import {
  getContactListThunk,
  getRegionListThunk,
} from '../../../redux/thunks/contact'

// ----------------------------------------------------------------------

export default function ServicesCreate() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isLoading, currentService } = useSelector(
    (state: RootState) => state.services,
  )

  const { id = 0 } = useParams()
  const isEdit = currentService !== undefined

  useEffect(() => {
    dispatch(getServiceThunk(+id))
    dispatch(getRegionListThunk())
    dispatch(getContactListThunk())
  }, [dispatch])

  const handleSave = (newId?: number | null) => {
    if (isEdit) {
      navigate(PATH_DASHBOARD.services.list)
    } else {
      window.location.href = `/dashboard/services/${newId}/edit` //так надо, чтобы запросить текущую услугу заново
    }
  }

  return (
    <DataLoadingPage
      loading={isLoading}
      title="Редактирование услуги"
      heading={!isEdit ? 'Создание услуги' : 'Редактирование услуги'}
      links={[
        { name: 'Главная', href: PATH_DASHBOARD.root },
        { name: 'Услуги', href: PATH_DASHBOARD.services.list },
        { name: !isEdit ? 'Новая услуга' : currentService?.title || '' },
      ]}
    >
      <ServicesEdit
        onSave={handleSave}
        slug={isEdit ? (currentService?.staticPath as string) : null}
      />
    </DataLoadingPage>
  )
}
