import { createSlice } from '@reduxjs/toolkit'
import {
  FullPublicationsItem,
  PublicationsItem,
} from '../../@types/publications'

// ----------------------------------------------------------------------

type PublicationsState = {
  isLoading: boolean
  error: boolean
  publicationsList: PublicationsItem[]
  currentPublicationsItem?: FullPublicationsItem
}

const initialState: PublicationsState = {
  isLoading: false,
  error: false,
  publicationsList: [],
}

const slice = createSlice({
  name: 'publications',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    getPublicationsListSuccess(state, action) {
      state.isLoading = false
      state.publicationsList = action.payload
    },

    getPublicationsItemSuccess(state, action) {
      state.isLoading = false
      state.currentPublicationsItem = action.payload
    },

    changePublicationsItemActiveStatus(state, action) {
      const list = state.publicationsList.map(publicationItem => {
        const active =
          publicationItem.id !== +action.payload
            ? publicationItem.active
            : publicationItem.active
            ? 0
            : 1
        return {
          ...publicationItem,
          active,
        }
      })
      state.publicationsList = list
    },

    deletePublicationsItem(state, action) {
      const list = state.publicationsList.filter(
        publicationItem => publicationItem.id !== +action.payload,
      )
      state.publicationsList = list
    },
  },
})

export const {
  startLoading,
  hasError,
  getPublicationsListSuccess,
  getPublicationsItemSuccess,
  changePublicationsItemActiveStatus,
  deletePublicationsItem,
} = slice.actions

export default slice.reducer
