// material
import { Container, Tab } from '@mui/material'
// hooks
import { useEffect, useState } from 'react'
import useSettings from '../../hooks/useSettings'
//store
import { getPageListThunk } from '../../redux/thunks/structure'
// components
import Page from '../../components/Page'
import { useDispatch } from '../../redux/store'
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import MainAdvantages from './MainAdvantages'
import MainSeo from './MainSeo'
import MainCases from './MainCases'
import MainSlider from './MainSlider'
import MainProducts from './MainProducts'

export default function MainPage() {
  const { themeStretch } = useSettings()
  const dispatch = useDispatch()

  const [currentTab, setCurrentTab] = useState<string | null>('1')

  useEffect(() => {
    dispatch(getPageListThunk())
  }, [dispatch])

  const handleChangeTab = (value: string | null) => {
    setCurrentTab(value)
  }

  return (
    <Page title="Главная страница">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs heading="Главная страница" links={[]} />

        <TabContext value={currentTab as string}>
          <TabList onChange={(e, value) => handleChangeTab(value)}>
            <Tab label="Товары" value="1" />
            <Tab label="Главный слайдер" value="2" />
            <Tab label="Преимущества" value="3" />
            <Tab label="Кейсы поставок" value="4" />
            <Tab label="SEO" value="5" />
          </TabList>
          <TabPanel value="1">
            <MainProducts />
          </TabPanel>
          <TabPanel value="2">
            <MainSlider />
          </TabPanel>
          <TabPanel value="3">
            <MainAdvantages slug='main' />
          </TabPanel>
          <TabPanel value="4">
            <MainCases />
          </TabPanel>
          <TabPanel value="5">
            <MainSeo />
          </TabPanel>
        </TabContext>
      </Container>
    </Page>
  )
}
