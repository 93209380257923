import { Button, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useState } from 'react'
import { Icon } from '@iconify/react'
import plusFill from '@iconify/icons-eva/plus-fill'
import DeleteIcon from '@mui/icons-material/Delete'
import TinyMCEEditor from 'components/editor/tinymce/new'

interface Slide {
  title: string
  content: string
}

export default function ServicesBlock({
  blocksProp,
  changeBlocks,
  metal = false,
}: {
  metal?: boolean
  blocksProp: any[]
  changeBlocks: (val: Slide[]) => void
}) {
  
  const addBlock = () => {
    const newBlock = [
      ...blocksProp,
      {
        title: '',
        content: '',
      },
    ]

    changeBlocks(newBlock)
  }

  const removeBlock = (index: number) => {
    const newBlock = () => {
      if (blocksProp.length <= 1) {
        return []
      }
      return [
        ...blocksProp.slice(0, index),
        ...blocksProp.slice(index + 1, blocksProp.length),
      ]
    }

    changeBlocks(newBlock())
  }

  const changeBlock = (index: number, field: string, value: unknown) => {
    const newBlock = blocksProp.map((item: any, idx) => {
      if (idx === index) {
        return {
          ...item,
          [field]: value,
        }
      }
      return item
    })

    changeBlocks(newBlock)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5, mt: 2 }}>
      {!metal && (
        <Button
          sx={{ width: 'fit-content' }}
          variant="contained"
          startIcon={<Icon icon={plusFill} />}
          onClick={addBlock}
        >
          Добавить блок
        </Button>
      )}

      {blocksProp.map((item, index) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
          key={index}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 10,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <TextField
                  label="Заголовок блока"
                  value={item.title}
                  onChange={e => changeBlock(index, 'title', e.target.value)}
                  sx={{ width: '80%' }}
                ></TextField>
                <div onClick={() => removeBlock(index)}>
                  <DeleteIcon sx={{ cursor: 'pointer' }} />
                </div>
              </Box>
              Описание:
              <TinyMCEEditor
                value={item.content}
                onChange={val => changeBlock(index, 'content', val)}
              />
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  )
}
