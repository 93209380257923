import { Card, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { useCallback, useEffect, useState } from 'react'
import { UploadSingleFile } from 'components/upload'
import { dispatch } from 'redux/store'
import { uploadFileThunk } from 'redux/thunks/files'
import { useSnackbar } from 'notistack'

interface Block {
  title: string
  description: string
  image: localFile | null
}

interface localFile extends File {
  fileName: string
  file: string
}

export default function SecondBlock({
  changeBlock,
  propBlock,
}: {
  changeBlock: (val: Block) => void
  propBlock: Block
}) {
  const [block, setBlock] = useState<Block>({
    title: '',
    description: '',
    image: null,
  })
  const { enqueueSnackbar } = useSnackbar()

  const handleDropFile = useCallback(async acceptedFiles => {
    const file = acceptedFiles[0]
    if (file) {
      const loadedFileName = await dispatch(uploadFileThunk(file))
      if (loadedFileName) {
        setBlock(prev => ({
          ...prev,
          image: {
            ...file,
            fileName: loadedFileName,
            preview: URL.createObjectURL(file),
          },
        }))
      } else {
        enqueueSnackbar('Ошибка загрузки файла', { variant: 'error' })
      }
    }
  }, [])

  const changeCase = (field: string, value: unknown) => {
    setBlock(prev => ({
      ...prev,
      [field]: value,
    }))
  }

  useEffect(() => {
    setBlock(propBlock)
  }, [])

  useEffect(() => {
    changeBlock(block)
  }, [block])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5, mt: 2 }}>
      <Card sx={{ p: 3 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            mt: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 10,
              mt: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '65%',
                flexDirection: 'column',
                gap: 2,
                mt: 2,
              }}
            >
              <TextField
                label="Заголовок блока"
                value={block.title}
                onChange={e => changeCase('title', e.target.value)}
              ></TextField>
              <TextField
                label="Описание блока"
                value={block.description}
                onChange={e => changeCase('description', e.target.value)}
              ></TextField>
            </Box>
            <UploadSingleFile
              sx={{ width: '30%' }}
              file={block.image}
              onDrop={acceptedFiles => handleDropFile(acceptedFiles)}
            />
          </Box>
        </Box>
      </Card>
    </Box>
  )
}
