import { createSlice } from '@reduxjs/toolkit'
import { ArticlesItem, FullArticlesItem } from '../../@types/articles'

// ----------------------------------------------------------------------

type ArticlesState = {
  isLoading: boolean
  error: boolean
  articlesList: ArticlesItem[]
  currentArticlesItem?: FullArticlesItem
}

const initialState: ArticlesState = {
  isLoading: false,
  error: false,
  articlesList: [],
}

const slice = createSlice({
  name: 'articles',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    getArticlesListSuccess(state, action) {
      state.isLoading = false
      state.articlesList = action.payload
    },

    getArticlesItemSuccess(state, action) {
      state.isLoading = false
      state.currentArticlesItem = action.payload
    },

    changeArticlesItemActiveStatus(state, action) {
      const list = state.articlesList.map(articlesItem => {
        const active =
          articlesItem.id !== +action.payload
            ? articlesItem.active
            : articlesItem.active
            ? 0
            : 1
        return {
          ...articlesItem,
          active,
        }
      })
      state.articlesList = list
    },

    changeArticlesItemShowMainStatus(state, action) {
      const list = state.articlesList.map(articlesItem => {
        const showOnMain =
          articlesItem.id !== +action.payload
            ? articlesItem.showOnMain
            : articlesItem.showOnMain
            ? 0
            : 1
        return {
          ...articlesItem,
          showOnMain,
        }
      })
      state.articlesList = list
    },

    deleteArticlesItem(state, action) {
      const list = state.articlesList.filter(
        articlesItem => articlesItem.id !== +action.payload,
      )
      state.articlesList = list
    },
  },
})

export const {
  startLoading,
  hasError,
  getArticlesListSuccess,
  getArticlesItemSuccess,
  changeArticlesItemActiveStatus,
  changeArticlesItemShowMainStatus,
  deleteArticlesItem,
} = slice.actions

export default slice.reducer
