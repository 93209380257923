import { Card, TextField } from '@mui/material'
import { Box } from '@mui/system'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import axios from 'utils/axios'
import { useSnackbar } from 'notistack'

type FormValuesProps = {
  metaH1: string
  metaTitle: string
  metaKeywords: string
  metaDescription: string
}

export default function AboutSeo() {
  const { enqueueSnackbar } = useSnackbar()

  const EditSchema = Yup.object().shape({
    metaH1: Yup.string().required('Введите заголовок'),
    metaTitle: Yup.string().required('Введите URL страницы'),
  })

  const [seo, setSeo] = useState<FormValuesProps | undefined>(undefined)

  const getFaq = async () => {
    const data = await axios.get(`/pages/about/seo`)
    setSeo(data.data)
  }

  useEffect(() => {
    getFaq()
  }, [])

  const formik = useFormik<FormValuesProps>({
    initialValues: {
      metaH1: '',
      metaTitle: '',
      metaKeywords: '',
      metaDescription: '',
    },
    validationSchema: EditSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await axios.put(`/pages/about/seo`, values)
        enqueueSnackbar('Cохранено', { variant: 'success' })
      } catch (error) {
        enqueueSnackbar('Ошибка сохранения', { variant: 'error' })
      }
      setSubmitting(false)
    },
  })
  const {
    errors,
    touched,
    getFieldProps,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    resetForm,
  } = formik

  useEffect(() => {
    if (seo) {
      setFieldValue('metaH1', seo.metaH1)
      setFieldValue('metaTitle', seo.metaTitle)
      setFieldValue('metaKeywords', seo.metaKeywords)
      setFieldValue('metaDescription', seo.metaDescription)
    } else {
      resetForm()
      setSeo(undefined)
    }
  }, [seo])

  return (
    <Card sx={{ p: 3 }}>
      <TextField
        {...getFieldProps('metaH1')}
        fullWidth
        type="text"
        label="Заголовок H1"
        error={Boolean(touched.metaH1 && errors.metaH1)}
        helperText={touched.metaH1 && errors.metaH1}
      />
      <Box sx={{ mb: 2 }} />
      <TextField
        {...getFieldProps('metaTitle')}
        fullWidth
        type="text"
        label="Meta Title"
        error={Boolean(touched.metaTitle && errors.metaTitle)}
        helperText={touched.metaTitle && errors.metaTitle}
      />
      <Box sx={{ mb: 2 }} />
      <TextField
        {...getFieldProps('metaKeywords')}
        fullWidth
        type="text"
        label="Meta Keywords"
        error={Boolean(touched.metaKeywords && errors.metaKeywords)}
        helperText={touched.metaKeywords && errors.metaKeywords}
      />
      <Box sx={{ mb: 2 }} />
      <TextField
        {...getFieldProps('metaDescription')}
        fullWidth
        type="text"
        multiline
        minRows={4}
        label="Meta Description"
        error={Boolean(touched.metaDescription && errors.metaDescription)}
        helperText={touched.metaDescription && errors.metaDescription}
      />
      <Box sx={{ mb: 2 }} />

      <LoadingButton
        sx={{ width: 'fit-content' }}
        variant="contained"
        loading={isSubmitting}
        onClick={() => handleSubmit()}
      >
        Сохранить
      </LoadingButton>
    </Card>
  )
}
