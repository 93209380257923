import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'
// slices
import userReducer from './slices/user'
import clientReducer from './slices/client'
import rolesReducer from './slices/userRole'
import structureReducer from './slices/structure'
import variableReducer from './slices/variable'
import catalogReducer from './slices/catalog'
import newsReducer from './slices/news'
import rewardsReducer from './slices/rewards'
import suppliersReducer from './slices/suppliers'
import contactReducer from './slices/contact'
import serviceReducer from './slices/services'
import bannerReducer from './slices/banners'
import adsReducer from './slices/ads'
import vacanciesReducer from './slices/vacancies'
import analyticsReducer from './slices/analytics'
import articlesReducer from './slices/articles'
import publicationsReducer from './slices/publications'
import tagReducer from './slices/tag'
import companyReducer from './slices/company'
import exportsReducer from './slices/exports'
import stockReducer from './slices/stock'
import landingsReducer from './slices/landings'
import categoryReducer from './slices/category'
import filtersReducer from './slices/filters'
import logsReducer from './slices/logs'
import reviewsReducer from './slices/reviews'

// new slices
import newCatalog from './slices/newCatalog'

//hr Slices
import hrPageReducer from './slices/hr/page'
import hrEventReducer from './slices/hr/event'
import hrArticlesReducer from './slices/hr/articles'
import hrVacanciesReducer from './slices/hr/vacancies'
import hrDirectionsReducer from './slices/hr/directions'
import hrCitiesReducer from './slices/hr/cities'
import hrManagerReducer from './slices/hr/manager'
import hrSuccessStoryReducer from './slices/hr/successStory'
import hrSpecialitiesReducer from './slices/hr/specialities'

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
}

const rootReducer = combineReducers({
  user: userReducer,
  client: clientReducer,
  roles: rolesReducer,
  structure: structureReducer,
  variable: variableReducer,
  catalog: catalogReducer,
  news: newsReducer,
  rewards: rewardsReducer,
  suppliers: suppliersReducer,
  contact: contactReducer,
  services: serviceReducer,
  banners: bannerReducer,
  ads: adsReducer,
  vacancies: vacanciesReducer,
  analytics: analyticsReducer,
  articles: articlesReducer,
  publications: publicationsReducer,
  tag: tagReducer,
  company: companyReducer,
  exports: exportsReducer,
  stock: stockReducer,
  landings: landingsReducer,
  category: categoryReducer,
  filters: filtersReducer,
  logs: logsReducer,
  hrPage: hrPageReducer,
  hrEvent: hrEventReducer,
  hrArticles: hrArticlesReducer,
  hrVacancies: hrVacanciesReducer,
  hrDirections: hrDirectionsReducer,
  hrCities: hrCitiesReducer,
  hrManager: hrManagerReducer,
  hrSuccessStory: hrSuccessStoryReducer,
  hrSpecialities: hrSpecialitiesReducer,
  newCatalog: newCatalog,
  reviews: reviewsReducer,
})

export { rootPersistConfig, rootReducer }
