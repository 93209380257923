import {
  Button,
  Card,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useCallback, useEffect, useState } from 'react'
import { Icon } from '@iconify/react'
import plusFill from '@iconify/icons-eva/plus-fill'
import { UploadSingleFile } from 'components/upload'
import { dispatch } from 'redux/store'
import { uploadFileThunk } from 'redux/thunks/files'
import { useSnackbar } from 'notistack'
import axios, { baseUrl } from 'utils/axios'
import { LoadingButton } from '@mui/lab'
import DeleteIcon from '@mui/icons-material/Delete'

interface Case {
  id: number | null
  title: string
  description: string
  image: localFile | string
  results: {
    result: string
  }[]
  active: boolean
  position: number
}

interface localFile extends File {
  fileName: string
  file: string
}

export default function MainCases() {
  const [cases, setCases] = useState<Case[]>([])
  const { enqueueSnackbar } = useSnackbar()

  const handleDropFile = useCallback(async (acceptedFiles, caseIndex) => {
    const file = acceptedFiles[0]
    if (file) {
      const loadedFileName = await dispatch(uploadFileThunk(file))
      if (loadedFileName) {
        setCases(prev =>
          prev.map((item, idx) => {
            if (idx === caseIndex) {
              return {
                ...item,
                image: {
                  ...file,
                  fileName: loadedFileName,
                  preview: URL.createObjectURL(file),
                },
              }
            }
            return item
          }),
        )
      } else {
        enqueueSnackbar('Ошибка загрузки файла', { variant: 'error' })
      }
    }
  }, [])

  const addCase = () => {
    setCases(prev => [
      ...prev,
      {
        id: null,
        active: false,
        position: 1,
        title: '',
        description: '',
        image: '',
        results: [
          {
            result: '',
          },
        ],
      },
    ])
  }

  const removeCase = (index: number) => {
    setCases(prev => {
      if (prev.length <= 1) {
        return []
      }
      return [...prev.slice(0, index), ...prev.slice(index + 1, prev.length)]
    })
  }

  const addResult = (caseIndex: number) => {
    setCases(prev =>
      prev.map((item, index) => {
        if (caseIndex === index) {
          return {
            ...item,
            results: [...item.results, { result: '' }],
          }
        }
        return item
      }),
    )
  }

  const removeResult = (index: number, resIdx: number) => {
    setCases(prev => {
      return prev.map((item, itemIdx) => {
        if (itemIdx === index) {
          if (item.results.length <= 1) {
            item.results = []
          } else {
            item.results.splice(resIdx, 1)
          }
        }
        return item
      })
    })
  }

  const changeCase = (
    index: number,
    field: string,
    value: unknown,
    additionalIndex?: number,
  ) => {
    if (field === 'title' || field === 'description' || field === 'active') {
      setCases(prev =>
        prev.map((item: any, idx) => {
          if (idx === index) {
            return {
              ...item,
              [field]: value,
            }
          }
          return item
        }),
      )
    } else if (field === 'results') {
      setCases(prev =>
        prev.map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              results: item.results.map(
                (result: { result: string }, resIdx) => {
                  if (resIdx === additionalIndex) {
                    result.result = value as string
                    return result
                  }
                  return result
                },
              ),
            }
          }
          return item
        }),
      )
    }
  }

  const getCases = async () => {
    const data = await axios.get('/supply-cases')
    setCases(
      data.data.map((item: Case) => {
        return {
          ...item,
          image: {
            ...(item.image as localFile),
            fileName: (item.image as localFile).file,
            preview: `${baseUrl}/files/supplyCase/${
              (item.image as localFile).file
            }`,
          },
        }
      }),
    )
  }

  const putCases = async () => {
    try {
      const data = await axios.put('/supply-cases', {
        supplyCases: cases.map((item: Case) => {
          return {
            ...item,
            image: (item.image as localFile).fileName,
          }
        }),
      })
      setCases(
        data.data.map((item: Case) => {
          return {
            ...item,
            image: {
              ...(item.image as localFile),
              fileName: (item.image as localFile).file,
              preview: `${baseUrl}/files/supplyCase/${
                (item.image as localFile).file
              }`,
            },
          }
        }),
      )
      enqueueSnackbar('Сохранено', { variant: 'success' })
    } catch (err) {
      enqueueSnackbar('При сохранении возникла ошибка', { variant: 'error' })
    }
  }

  useEffect(() => {
    getCases()
  }, [])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5, mt: 2 }}>
      <Button
        sx={{ width: 'fit-content', alignSelf: 'flex-end' }}
        variant="contained"
        startIcon={<Icon icon={plusFill} />}
        onClick={addCase}
      >
        Добавить кейс
      </Button>

      {cases.map((item, index) => (
        <Card sx={{ p: 3 }} key={index}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <FormControlLabel
              labelPlacement="start"
              control={
                <Switch
                  checked={!!item.active}
                  onChange={e => changeCase(index, 'active', !!!item.active)}
                />
              }
              label={
                <>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Активность
                  </Typography>
                </>
              }
              sx={{
                mx: 0,
                mb: 3,
                width: 'fit-content',
                justifyContent: 'space-between',
              }}
            />
            <div onClick={() => removeCase(index)}>
              <DeleteIcon sx={{ cursor: 'pointer' }} />
            </div>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              mt: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 10,
                mt: 2,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: '65%',
                  flexDirection: 'column',
                  gap: 2,
                  mt: 2,
                }}
              >
                <TextField
                  label="Название кейса"
                  value={item.title}
                  onChange={e => changeCase(index, 'title', e.target.value)}
                ></TextField>
                <TextField
                  label="Описание кейса"
                  value={item.description}
                  onChange={e =>
                    changeCase(index, 'description', e.target.value)
                  }
                ></TextField>
              </Box>
              <UploadSingleFile
                sx={{ width: '30%' }}
                file={item.image}
                onDrop={acceptedFiles => handleDropFile(acceptedFiles, index)}
              />
            </Box>

            {item.results.map((result, resIdx) => (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: 5,
                }}
                key={resIdx + 'result'}
              >
                <TextField
                  sx={{ width: '100%' }}
                  label="Результат кейса"
                  value={result.result}
                  onChange={e =>
                    changeCase(index, 'results', e.target.value, resIdx)
                  }
                ></TextField>
                <div onClick={() => removeResult(index, resIdx)}>
                  <DeleteIcon sx={{ cursor: 'pointer' }} />
                </div>
              </Box>
            ))}
            <Button
              sx={{
                height: 'fit-content',
                width: 'fit-content',
                alignSelf: 'flex-end',
              }}
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
              onClick={() => addResult(index)}
            >
              Добавить результат
            </Button>
          </Box>
        </Card>
      ))}

      <LoadingButton
        sx={{ width: 'fit-content' }}
        variant="contained"
        onClick={putCases}
      >
        Сохранить
      </LoadingButton>
    </Box>
  )
}
