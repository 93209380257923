import plusFill from '@iconify/icons-eva/plus-fill'
import { Icon } from '@iconify/react'
import DeleteIcon from '@mui/icons-material/Delete'
import MenuIcon from '@mui/icons-material/Menu'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  Card,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd'
import axios from 'utils/axios'

interface Advantage {
  id: number | null
  title: string
  description: string
  active: boolean
  position: number
}

interface IProps {
  slug: string
  visibilityButton?: boolean
  parentEvent?: boolean
}

export default function MainAdvantages({
  slug,
  visibilityButton = true,
  parentEvent,
}: IProps) {
  const [advantages, setAdvantage] = useState<Advantage[]>([])
  const { enqueueSnackbar } = useSnackbar()

  const getAdvantages = async () => {
    const data = await axios.get<Advantage[]>(`/advantages?slug=${slug}`)
    setAdvantage(data.data)
  }

  useEffect(() => {
    if (parentEvent) putAdvantages()
  }, [parentEvent])

  const putAdvantages = async () => {
    try {
      const data = await axios.put<Advantage[]>('/advantages', {
        slug: slug,
        advantages: advantages,
      })
      setAdvantage(data.data)
      enqueueSnackbar('"Преимущества" сохранены', { variant: 'success' })
    } catch (err) {
      enqueueSnackbar('Ошибка сохранения "Преимущества"', {
        variant: 'error',
      })
    }
  }

  const changeAdvantageTitle = (index: number, value: string) => {
    setAdvantage(prev =>
      prev.map((val, idx) => {
        if (idx === index) {
          return {
            ...val,
            title: value,
          }
        }
        return val
      }),
    )
  }
  const changeAdvantageDescription = (index: number, value: string) => {
    setAdvantage(prev =>
      prev.map((val, idx) => {
        if (idx === index) {
          return {
            ...val,
            description: value,
          }
        }
        return val
      }),
    )
  }

  const changeAdvantageActive = (index: number, value: boolean) => {
    setAdvantage(prev =>
      prev.map((val, idx) => {
        if (idx === index) {
          return {
            ...val,
            active: value,
          }
        }
        return val
      }),
    )
  }

  const addAdvantage = () => {
    setAdvantage(prev => [
      ...prev,
      {
        id: null,
        title: '',
        description: '',
        active: false,
        position: 1,
      },
    ])
  }

  const deleteAdvantage = (index: number) => {
    setAdvantage(prev => prev.filter((item, idx) => idx !== index))
  }

  const reorder = (list: Advantage[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const dragEnd = async (
    dropResult: DropResult,
    provided?: ResponderProvided,
  ) => {
    if (!dropResult.destination) {
      return
    }

    const items = reorder(
      advantages,
      dropResult.source.index,
      dropResult.destination.index,
    )

    const sort = items.map(item => item.id)

    setAdvantage(items)

    try {
      const { data } = await axios.post(`/advantages/update-sort/${slug}`, {
        sortOrder: sort,
      })
      setAdvantage(data)
      enqueueSnackbar('Сохранено', { variant: 'success' })
    } catch (err) {
      enqueueSnackbar('При сохранении возникла ошибка', { variant: 'error' })
    }
  }

  useEffect(() => {
    getAdvantages()
  }, [])

  return (
    <Box sx={{ p: 3, pb: 10, display: 'flex', flexDirection: 'column' }}>
      <Button
        variant="contained"
        startIcon={<Icon icon={plusFill} />}
        onClick={addAdvantage}
        sx={{ alignSelf: 'end' }}
      >
        Добавить преимущество
      </Button>
      <Grid container spacing={3} sx={{ mt: 2 }}>
        <DragDropContext onDragEnd={dragEnd}>
          <Droppable droppableId="droppable" direction="vertical">
            {(droppableProvided: DroppableProvided) => (
              <Box
                sx={{ width: '100%' }}
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                {advantages.map((advantage, index) => (
                  <Card sx={{ mb: 3, p: 3 }} key={index}>
                    <Draggable draggableId={index + '1'} index={index}>
                      {(
                        draggableProvided: DraggableProvided,
                        snapshot: DraggableStateSnapshot,
                      ) => {
                        return (
                          <>
                            <FormControlLabel
                              labelPlacement="start"
                              control={
                                <Switch
                                  checked={!!advantage.active}
                                  onChange={e =>
                                    changeAdvantageActive(
                                      index,
                                      !!!advantage.active,
                                    )
                                  }
                                />
                              }
                              label={
                                <>
                                  <Typography
                                    variant="body2"
                                    sx={{ color: 'text.secondary' }}
                                  >
                                    Активность
                                  </Typography>
                                </>
                              }
                              sx={{
                                mx: 0,
                                mb: 3,
                                width: 'fit-content',
                                justifyContent: 'space-between',
                              }}
                            />
                            <Box
                              key={index}
                              sx={{
                                display: 'flex',
                                alignItems: 'center ',
                                mb: 2,
                                gap: 5,
                              }}
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}
                            >
                              <div {...draggableProvided.dragHandleProps}>
                                <MenuIcon />
                              </div>
                              <TextField
                                fullWidth
                                type="text"
                                label="Название преимущества"
                                value={advantage.title}
                                onChange={e => {
                                  changeAdvantageTitle(index, e.target.value)
                                }}
                              />
                              <TextField
                                fullWidth
                                type="text"
                                label="Описание преимущества"
                                value={advantage.description}
                                onChange={e => {
                                  changeAdvantageDescription(
                                    index,
                                    e.target.value,
                                  )
                                }}
                              />
                              <div onClick={() => deleteAdvantage(index)}>
                                <DeleteIcon sx={{ cursor: 'pointer' }} />
                              </div>
                            </Box>
                          </>
                        )
                      }}
                    </Draggable>
                  </Card>
                ))}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>

      {visibilityButton && advantages.length > 0 && (
        <LoadingButton
          sx={{ width: 'fit-content' }}
          variant="contained"
          onClick={putAdvantages}
        >
          Сохранить
        </LoadingButton>
      )}
    </Box>
  )
}
