import {AppThunk} from "../store";
import axios from "../../utils/axios";
import {
    startLoading,
    hasError,
    getPageListSuccess,
    getPageSuccess,
    sortOrderUpdated,
    getRobotsSuccess, getSitemapSuccess
} from "redux/slices/structure";

export const getPageListThunk = ():AppThunk => async dispatch => {
    dispatch(startLoading());
    try {
        const response = await axios.get('/pages');
        dispatch(getPageListSuccess(response.data));
    } catch (error) {
        dispatch(hasError(error));
    }
}

export const getPageThunk = (
    id: number
):AppThunk => async dispatch => {
    if(id === 0){
        dispatch(getPageSuccess(undefined));
    }
    else {
        dispatch(startLoading());
        try {
            const response = await axios.get(`/pages/${id}`);
            dispatch(getPageSuccess(response.data));
        } catch (error) {
            dispatch(hasError(error));
        }
    }
}

export const updateSortThunk = (
    sort: number[][]
):AppThunk => async dispatch => {
    dispatch(startLoading());
    try {
        const params = {
            sortOrder: sort,
        }
        await axios.post(`/pages/update_sort`, params);
        dispatch(sortOrderUpdated());
    } catch (error) {
        dispatch(hasError(error));
    }
}

export const deletePageThunk = (
    pageId: string
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        const response = await axios.delete(`/pages/${pageId}`);
        if(response.data) return true;
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const changeActivePageThunk = (
    pageId: string,
    active: boolean,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        const params = {
            active: active ? 1 : 0,
        }
        const response = await axios.post(`/pages/${pageId}/activate`, params);
        if(response.data.result) return true;
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const createPageThunk = (
    params: any,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        await axios.post(`/pages`, params);
        return true;
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const editPageThunk = (
    pageId: string,
    params: any,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        await axios.put(`/pages/${pageId}`, params);
        return true;
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const getRobotsThunk = ():AppThunk => async dispatch => {
    dispatch(startLoading());
    try {
        const response = await axios.get(`/robots`);
        dispatch(getRobotsSuccess(response.data.result));
    } catch (error) {
        dispatch(hasError(error));
    }
}

export const editRobotsThunk = (
    params: any,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        await axios.put(`/robots`, params);
        return true;
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const getSitemapThunk = ():AppThunk => async dispatch => {
    dispatch(startLoading());
    try {
        const response = await axios.get(`/sitemap`);
        dispatch(getSitemapSuccess(response.data.result));
    } catch (error) {
        dispatch(hasError(error));
    }
}

export const editSitemapThunk = (
    params: any,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        await axios.put(`/sitemap`, params);
        return true;
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}