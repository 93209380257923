import { createSlice } from '@reduxjs/toolkit'
import { FullSupplier, Supplier } from '../../@types/suppliers'

// ----------------------------------------------------------------------

type SuppliersState = {
  isLoading: boolean
  error: boolean
  suppliersList: Supplier[]
  currentSupplier?: FullSupplier
}

const initialState: SuppliersState = {
  isLoading: false,
  error: false,
  suppliersList: [],
}

const slice = createSlice({
  name: 'suppliers',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    getSuppliersListSuccess(state, action) {
      state.isLoading = false
      state.suppliersList = action.payload
    },

    getSupplierSuccess(state, action) {
      state.isLoading = false
      state.currentSupplier = action.payload
    },

    changeSupplierActiveStatus(state, action) {
      const list = state.suppliersList.map(item => {
        const active =
          item.id !== +action.payload ? item.active : item.active ? 0 : 1
        return {
          ...item,
          active,
        }
      })
      state.suppliersList = list
    },

    deleteSupplier(state, action) {
      const list = state.suppliersList.filter(
        item => item.id !== +action.payload,
      )
      state.suppliersList = list
    },

    updateSupplierSortOrder(state, action) {
      const sort: number[] = action.payload
      const list: Supplier[] = []
      sort.forEach(value => {
        const item = state.suppliersList.find(item => item.id === value)
        if (item !== undefined) list.push(item)
      })
      state.suppliersList = list
    },
  },
})

export const {
  startLoading,
  hasError,
  getSuppliersListSuccess,
  getSupplierSuccess,
  changeSupplierActiveStatus,
  deleteSupplier,
  updateSupplierSortOrder,
} = slice.actions

export default slice.reducer
