import { createSlice } from '@reduxjs/toolkit'
import { FullService, Service } from '../../@types/services'
import { FullPage } from '../../@types/structure'

// ----------------------------------------------------------------------

interface NewService extends Omit<FullPage, 'parentId' | 'type' | 'link'> {
  services: Service[]
}

type ServicesState = {
  isLoading: boolean
  error: boolean
  serviceList: {
    metal: NewService
    service: NewService
    customers: NewService
  }
  currentService?: FullService
}

const initialState: ServicesState = {
  isLoading: false,
  error: false,
  serviceList: {
    metal: {
      id: 0,
      staticPath: '',
      metaDescription: '',
      metaH1: '',
      metaTitle: '',
      metaKeywords: '',
      services: [],
      active: 0,
      content: '',
      title: '',
      sortOrder: 0,
      files: [],
    },
    service: {
      id: 0,
      staticPath: '',
      metaDescription: '',
      metaH1: '',
      metaTitle: '',
      metaKeywords: '',
      services: [],
      active: 0,
      content: '',
      title: '',
      sortOrder: 0,
      files: [],
    },
    customers: {
      id: 0,
      staticPath: '',
      metaDescription: '',
      metaH1: '',
      metaTitle: '',
      metaKeywords: '',
      services: [],
      active: 0,
      content: '',
      title: '',
      sortOrder: 0,
      files: [],
    },
  },
}

const slice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    getServiceListSuccess(state, action) {
      state.isLoading = false
      state.serviceList = action.payload
    },
    getServiceListMetalSuccess(state, action) {
      state.isLoading = false
      state.serviceList.metal.services = action.payload
    },

    getServiceListNameSuccess(
      state,
      action: {
        payload: {
          data: any
          type: 'metal' | 'service' | 'customers'
        }
        type: string
      },
    ) {
      state.serviceList[action.payload.type].services = action.payload.data

      state.isLoading = false
    },
    getServiceSuccess(state, action) {
      state.isLoading = false
      state.currentService = action.payload
    },

    changeServiceActiveStatus(state, action) {
      Object.keys(state.serviceList).forEach(key => {
        state.serviceList[key as keyof typeof state.serviceList].services =
          state.serviceList[key as keyof typeof state.serviceList].services.map(
            item => {
              const active =
                item.id !== +action.payload ? item.active : item.active ? 0 : 1
              return {
                ...item,
                active,
              }
            },
          )
      })
    },

    deleteService(state, action) {
      Object.keys(state.serviceList).forEach(key => {
        state.serviceList[key as keyof typeof state.serviceList].services =
          state.serviceList[
            key as keyof typeof state.serviceList
          ].services.filter(item => item.id !== +action.payload)
      })
    },

    updateServiceSortOrderNew(
      state,
      action: {
        payload: {
          sort: any
          type: 'metal' | 'service' | 'customers'
        }
        type: string
      },
    ) {
      const sort: number[] = action.payload.sort
      const list: any[] = []

      sort.forEach(value => {
        const item = state.serviceList[action.payload.type].services.find(
          item => item.id === value,
        )
        if (item !== undefined) list.push(item)
      })
      state.serviceList[action.payload.type].services = list
    },
    updateServiceSortOrder(state, action) {
      // TODO: переделать под новую структуру
      const sort: number[] = action.payload
      const list: Service[] = []
      sort.forEach(value => {
        Object.keys(state.serviceList).forEach(key => {
          const item = state.serviceList[
            key as keyof typeof state.serviceList
          ].services.find(item => item.id === value)
          if (item !== undefined) list.push(item)
        })
      })
      // state.serviceList = list
    },
  },
})

export const {
  startLoading,
  hasError,
  getServiceListSuccess,
  getServiceSuccess,
  changeServiceActiveStatus,
  deleteService,
  updateServiceSortOrder,
  getServiceListMetalSuccess,
  getServiceListNameSuccess,
  updateServiceSortOrderNew,
} = slice.actions

export default slice.reducer
