import { AppThunk } from '../store'
import axios from '../../utils/axios'
import {
  startLoading,
  hasError,
  getTagListSuccess,
  getTagItemSuccess,
  deleteTagItem,
  changeTagItemActiveStatus,
  getCategoryListSuccess,
  getGrupListSuccess,
  getItemListSuccess,
} from 'redux/slices/tag'

export const getTagListThunk = (): AppThunk => async dispatch => {
  dispatch(startLoading())
  try {
    const response = await axios.get('/tags')
    dispatch(getTagListSuccess(response.data))
  } catch (error) {
    dispatch(hasError(error))
  }
}

export const getCategoryThunk = (): AppThunk => async dispatch => {
  dispatch(startLoading())
  try {
    const response = await axios.get(
      // '/catalog/export/categories?filter[fullOnly]=1',
      '/catalog/categories?filter[parentId]=0',
    )
    dispatch(getCategoryListSuccess(response.data))
  } catch (error) {
    dispatch(hasError(error))
  }
}

export const getGrupThunk =
  (id: string): AppThunk =>
  async dispatch => {
    if (id === '') {
      dispatch(getGrupListSuccess(0))
    } else {
      try {
        const response = await axios.get(
          // `/catalog/export/groups?filter[parentIds][]=${id}`,
          `/catalog/categories?filter[parentId]=${id}`,
        )

        dispatch(getGrupListSuccess(response.data))
      } catch (error) {
        dispatch(hasError(error))
      }
    }
  }

export const getItemThunk =
  (
    idCat: string,
    stock: string,
    query: string,
    perPage: string,
    page: number,
    region?: string,
  ): AppThunk =>
  async dispatch => {
    try {
      const response = await axios.get(
        `catalog/export/items?filter[category_id]=${idCat}&filter[stock_id]=${stock}&query=${query}&per_page=${perPage}&page=${page}&region=${region}`,
      )
      dispatch(getItemListSuccess(response.data))
    } catch (error) {
      dispatch(hasError(error))
    }
  }
// export const getItemThunk =
//   (idCat: string, idGrup: string): AppThunk =>
//   async dispatch => {
//     if (idCat === '') {
//       // dispatch(getItemListSuccess(0))
//     } else {
//       try {
//         const response = await axios.get(
//           `catalog/export/items?filter[category_id]=${idCat}${
//             idGrup ? `&filter[groupIds][]=${idGrup}` : ''
//           }`,
//         )
//         dispatch(getItemListSuccess(response.data))
//       } catch (error) {
//         dispatch(hasError(error))
//       }
//     }
//   }

export const getTagItemThunk =
  (id: string): AppThunk =>
  async dispatch => {
    if (id === '') {
      dispatch(getTagItemSuccess(undefined))
    } else {
      dispatch(startLoading())
      try {
        const response = await axios.get(`/tags/${id}`)
        dispatch(getTagItemSuccess(response.data))
      } catch (error) {
        dispatch(hasError(error))
      }
    }
  }

export const createTagItemThunk =
  (params: any): AppThunk<Promise<boolean>> =>
  async dispatch => {
    try {
      await axios.post(`/tags`, params)
      return true
    } catch (error) {
      dispatch(hasError(error))
    }
    return false
  }

export const editTagItemThunk =
  (id: string, params: any): AppThunk<Promise<boolean>> =>
  async dispatch => {
    try {
      await axios.put(`/tags/${id}`, params)
      return true
    } catch (error) {
      dispatch(hasError(error))
    }
    return false
  }

export const deleteTagItemThunk =
  (id: string): AppThunk<Promise<boolean>> =>
  async dispatch => {
    try {
      const response = await axios.delete(`/tags/${id}`)
      if (response.data.result) {
        dispatch(deleteTagItem(id))
        return true
      }
    } catch (error) {
      dispatch(hasError(error))
    }
    return false
  }

export const changeActiveTagItemThunk =
  (tagItemId: number): AppThunk<Promise<boolean>> =>
  async dispatch => {
    try {
      const response = await axios.get(`/tags/${tagItemId}/change-active`)
      if (response) {
        dispatch(changeTagItemActiveStatus(tagItemId))
        return true
      }
    } catch (error) {
      dispatch(hasError(error))
    }
    return false
  }
