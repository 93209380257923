import plusFill from '@iconify/icons-eva/plus-fill'
import { Icon } from '@iconify/react'
import DeleteIcon from '@mui/icons-material/Delete'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  Card,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import TinyMCEEditorNew from 'components/editor/tinymce/new'
import { UploadSingleFile } from 'components/upload'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useState } from 'react'
import { dispatch } from 'redux/store'
import { uploadFileThunk } from 'redux/thunks/files'
import axios, { baseUrl } from 'utils/axios'

interface Slide {
  year: string
  title: string
  description: string
  image: localFile | null
  secondImage: localFile | null
  active: boolean
  position: number
  images?: any[]
}

interface localFile extends File {
  fileName: string
  file: string
}

export default function AboutHistory() {
  const [slides, setSlides] = useState<Slide[]>([])
  const { enqueueSnackbar } = useSnackbar()

  const handleDropFile = useCallback(
    async (acceptedFiles, caseIndex, imgOrder: 'image' | 'secondImage') => {
      const file = acceptedFiles[0]
      if (file) {
        const loadedFileName = await dispatch(uploadFileThunk(file))
        if (loadedFileName) {
          setSlides(prev =>
            prev.map((item, idx) => {
              if (idx === caseIndex) {
                return {
                  ...item,
                  [imgOrder]: {
                    ...file,
                    fileName: loadedFileName,
                    preview: URL.createObjectURL(file),
                  },
                }
              }
              return item
            }),
          )
        } else {
          enqueueSnackbar('Ошибка загрузки файла', { variant: 'error' })
        }
      }
    },
    [],
  )

  const addCase = () => {
    setSlides(prev => [
      ...prev,
      {
        year: '',
        title: '',
        description: '',
        image: null,
        secondImage: null,
        active: false,
        position: 1,
      },
    ])
  }

  const removeCase = (index: number) => {
    setSlides(prev => {
      if (prev.length <= 1) {
        return []
      }
      return [...prev.slice(0, index), ...prev.slice(index + 1, prev.length)]
    })
  }

  const changeCase = (index: number, field: string, value: unknown) => {
    setSlides(prev =>
      prev.map((item: any, idx) => {
        if (idx === index) {
          return {
            ...item,
            [field]: value,
          }
        }
        return item
      }),
    )
  }

  const getHistory = async () => {
    const data = await axios.get('/history-stages')

    setSlides(
      data.data.map((item: Slide) => {
        const image = item.images?.length
          ? {
              fileName: item.images[0].file,
              preview: `${baseUrl}/files/historyStages/${item.images[0].file}`,
            }
          : ''

        const secondImage =
          item.images !== undefined && item.images.length > 1
            ? {
                fileName: item.images[1].file,
                preview: `${baseUrl}/files/historyStages/${item.images[1].file}`,
              }
            : ''

        return {
          ...item,
          image,
          secondImage,
        }
      }),
    )
  }

  const putHistory = async () => {
    try {
      const data = await axios.put('/history-stages', {
        historyStages: slides.map((item: Slide) => {
          return {
            ...item,
            image: item.image?.fileName ? item.image.fileName : 'null',
            secondImage: item.secondImage?.fileName
              ? item.secondImage.fileName
              : 'null',
          }
        }),
      })

      setSlides(
        data.data.map((item: Slide) => {
          const image = item.images?.length
            ? {
                fileName: item.images[0].file,
                preview: `${baseUrl}/files/historyStages/${item.images[0].file}`,
              }
            : ''

          const secondImage =
            item.images !== undefined && item.images.length > 1
              ? {
                  fileName: item.images[1].file,
                  preview: `${baseUrl}/files/historyStages/${item.images[1].file}`,
                }
              : ''

          return {
            ...item,
            image,
            secondImage,
          }
        }),
      )
      enqueueSnackbar('Сохранено', { variant: 'success' })
    } catch (err) {
      enqueueSnackbar('При сохранении возникла ошибка', { variant: 'error' })
    }
  }

  useEffect(() => {
    getHistory()
  }, [])

  const handleRemoveFile = (
    index: number,
    imgOrder: 'image' | 'secondImage',
  ) => {
    setSlides(prev =>
      prev.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            [imgOrder]: null,
          }
        }
        return item
      }),
    )
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5, mt: 2 }}>
      <Button
        sx={{ width: 'fit-content' }}
        variant="contained"
        startIcon={<Icon icon={plusFill} />}
        onClick={addCase}
      >
        Добавить фактоид
      </Button>

      {slides.map((item, index) => (
        <Card sx={{ p: 3 }} key={index}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <FormControlLabel
              labelPlacement="start"
              control={
                <Switch
                  checked={!!item.active}
                  onChange={e => changeCase(index, 'active', !!!item.active)}
                />
              }
              label={
                <>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Активность
                  </Typography>
                </>
              }
              sx={{
                mx: 0,
                mb: 3,
                width: 'fit-content',
                justifyContent: 'space-between',
              }}
            />
            <div onClick={() => removeCase(index)}>
              <DeleteIcon sx={{ cursor: 'pointer' }} />
            </div>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              mt: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 10,
                mt: 2,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: '65%',
                  flexDirection: 'column',
                  gap: 2,
                  mt: 2,
                }}
              >
                <TextField
                  label="Год"
                  value={item.year}
                  onChange={e => {
                    const yearRegex = /^\d+$/
                    if (yearRegex.test(e.target.value)) {
                      changeCase(index, 'year', e.target.value)
                    }
                    if (e.target.value === '') {
                      changeCase(index, 'year', e.target.value)
                    }
                  }}
                ></TextField>
                <TextField
                  label="Название"
                  value={item.title}
                  onChange={e => changeCase(index, 'title', e.target.value)}
                ></TextField>
                Описание:
                <TinyMCEEditorNew
                  value={item.description}
                  onChange={val => changeCase(index, 'description', val)}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  width: '30%',
                  flexDirection: 'column',
                  gap: 2,
                  mt: 2,
                }}
              >
                <Box>
                  <UploadSingleFile
                    file={item.image}
                    onDrop={acceptedFiles =>
                      handleDropFile(acceptedFiles, index, 'image')
                    }
                  />

                  {item.image && (
                    <Button
                      color="error"
                      onClick={() => handleRemoveFile(index, 'image')}
                    >
                      удалить
                    </Button>
                  )}
                </Box>

                <Box>
                  <UploadSingleFile
                    file={item.secondImage}
                    onDrop={acceptedFiles =>
                      handleDropFile(acceptedFiles, index, 'secondImage')
                    }
                  />

                  {item.secondImage && (
                    <Button
                      color="error"
                      onClick={() => handleRemoveFile(index, 'secondImage')}
                    >
                      удалить
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Card>
      ))}
      {slides.length > 0 && (
        <LoadingButton
          sx={{ width: 'fit-content' }}
          variant="contained"
          onClick={putHistory}
        >
          Сохранить
        </LoadingButton>
      )}
    </Box>
  )
}
