import {
  Button,
  Card,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { useCallback, useEffect, useState } from 'react'
import { Icon } from '@iconify/react'
import plusFill from '@iconify/icons-eva/plus-fill'
import { UploadSingleFile } from 'components/upload'
import { dispatch } from 'redux/store'
import { uploadFileThunk } from 'redux/thunks/files'
import { useSnackbar } from 'notistack'
import TinyMCEEditorNew from 'components/editor/tinymce/new'
import DeleteIcon from '@mui/icons-material/Delete'

interface Equipment {
  title: string
  description: string
  active: boolean
  position: number
  image: localFile | null
}

interface localFile extends File {
  fileName: string
  file: string
}

export default function ServicesEquipment({
  changeBlock,
  propBlock,
}: {
  changeBlock: (val: Equipment[]) => void
  propBlock: Equipment[]
}) {
  const [equipment, setEquipment] = useState<Equipment[]>([])
  const { enqueueSnackbar } = useSnackbar()

  const handleDropFile = useCallback(async (acceptedFiles, EquipmentIndex) => {
    const file = acceptedFiles[0]
    if (file) {
      const loadedFileName = await dispatch(uploadFileThunk(file))
      if (loadedFileName) {
        setEquipment(prev =>
          prev.map((item, idx) => {
            if (idx === EquipmentIndex) {
              return {
                ...item,
                image: {
                  ...file,
                  fileName: loadedFileName,
                  preview: URL.createObjectURL(file),
                },
              }
            }
            return item
          }),
        )
      } else {
        enqueueSnackbar('Ошибка загрузки файла', { variant: 'error' })
      }
    }
  }, [])

  const addEquipment = () => {
    setEquipment(prev => [
      ...prev,
      {
        title: '',
        description: '',
        image: null,
        active: false,
        position: 1,
      },
    ])
  }

  const removeEquipment = (index: number) => {
    setEquipment(prev => {
      if (prev.length <= 1) {
        return []
      }
      return [...prev.slice(0, index), ...prev.slice(index + 1, prev.length)]
    })
  }

  const changeEquipment = (index: number, field: string, value: unknown) => {
    setEquipment(prev =>
      prev.map((item: any, idx) => {
        if (idx === index) {
          return {
            ...item,
            [field]: value,
          }
        }
        return item
      }),
    )
  }

  useEffect(() => {
    setEquipment(propBlock)
  }, [])

  useEffect(() => {
    changeBlock(equipment)
  }, [equipment])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5, mt: 2 }}>
      <Button
        sx={{ width: 'fit-content' }}
        variant="contained"
        startIcon={<Icon icon={plusFill} />}
        onClick={addEquipment}
      >
        Добавить оборудование
      </Button>

      {equipment.map((item, index) => (
        <Card sx={{ p: 3 }} key={index}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <FormControlLabel
              labelPlacement="start"
              control={
                <Switch
                  checked={!!item.active}
                  onChange={e =>
                    changeEquipment(index, 'active', !!!item.active)
                  }
                />
              }
              label={
                <>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Активность
                  </Typography>
                </>
              }
              sx={{
                mx: 0,
                mb: 3,
                width: 'fit-content',
                justifyContent: 'space-between',
              }}
            />
            <div onClick={() => removeEquipment(index)}>
              <DeleteIcon sx={{ cursor: 'pointer' }} />
            </div>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              mt: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 10,
                mt: 2,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: '65%',
                  flexDirection: 'column',
                  gap: 2,
                  mt: 2,
                }}
              >
                <TextField
                  label="Название"
                  value={item.title}
                  onChange={e =>
                    changeEquipment(index, 'title', e.target.value)
                  }
                ></TextField>
                Описание:
                <TinyMCEEditorNew
                  value={item.description}
                  onChange={val => changeEquipment(index, 'description', val)}
                />
              </Box>
              <UploadSingleFile
                sx={{ width: '30%' }}
                file={item.image}
                onDrop={acceptedFiles => handleDropFile(acceptedFiles, index)}
              />
            </Box>
          </Box>
        </Card>
      ))}
    </Box>
  )
}
