import { useNavigate, useParams } from 'react-router-dom'
//store
import { useEffect } from 'react'
import { RootState, useDispatch, useSelector } from '../../../redux/store'

import DataLoadingPage from 'components/DataLoadingPage'
import { PATH_DASHBOARD } from '../../../routes/paths'
import {
  getServiceListThunk,
  getServiceThunk,
} from '../../../redux/thunks/services'
import ServicesEdit from '../../../components/services/ServicesEdit'
import {
  getContactListThunk,
  getRegionListThunk,
} from '../../../redux/thunks/contact'

// ----------------------------------------------------------------------

export default function ServicesMetal() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isLoading, currentService } = useSelector(
    (state: RootState) => state.services,
  )

  const { id = 0 } = useParams()
  const isEdit = currentService !== undefined

  const { serviceList } = useSelector((state: RootState) => state.services)

  useEffect(() => {
    dispatch(getServiceListThunk())
    dispatch(getRegionListThunk())
    dispatch(getContactListThunk())
  }, [dispatch])

  useEffect(() => {
    dispatch(getServiceThunk(serviceList.customers.id))
  }, [serviceList])

  const handleSave = () => {
    // navigate(PATH_DASHBOARD.services.root)
  }

  return (
    <DataLoadingPage
      loading={isLoading}
      title="Редактирование услуги Покупателям"
      heading={'Редактирование услуги Покупателям'}
      links={[
        { name: 'Главная', href: PATH_DASHBOARD.root },
        { name: 'Услуги', href: PATH_DASHBOARD.services.list },
      ]}
    >
      <ServicesEdit
        onSave={handleSave}
        slug={isEdit ? serviceList.customers.staticPath : null}
        noUrl={true}
      />
    </DataLoadingPage>
  )
}
