import { createSlice } from '@reduxjs/toolkit'
import { itemNewList } from '../../@types/newCatalog'
import { IMeta } from '../../@types/meta'

// ----------------------------------------------------------------------

type CatalogState = {
  isLoading: boolean
  error: boolean
  meta: IMeta
  itemNewList: itemNewList[]
  demandList: {
    id: number
    stockId: number
    price: number
  }[]
  specialOfferItems: {
    id: number
    stockId: number
    price: number
  }[]
}

const initialState: CatalogState = {
  isLoading: false,
  error: false,
  meta: {
    current_page: 1,
    from: 0,
    last_page: 1,
    path: '',
    per_page: 0,
    to: 0,
    total: 0,
  },
  itemNewList: [],
  demandList: [],
  specialOfferItems: [],
}

const slice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
    changeItemNew(state, action) {
      state.itemNewList = state.itemNewList.map(item => {
        if (item.id === action.payload.itemId) {
          return {
            ...item,
            new: action.payload.val,
          }
        }
        return item
      })
    },
    getItemNewListSuccess(state, action) {
      state.isLoading = false
      state.itemNewList = action.payload.data
      state.meta = action.payload.meta
    },
    getDemandItemsSuccess(state, action) {
      state.isLoading = false
      state.demandList = action.payload.map((item: any) => {
        return {
          id: item.id,
          stockId: item.stockId,
          price: item.price,
        }
      })
    },
    getSpecialOfferItemSuccess(state, action) {
      state.isLoading = false
      state.specialOfferItems = action.payload
    },
  },
})

export const {
  startLoading,
  hasError,
  changeItemNew,
  getItemNewListSuccess,
  getDemandItemsSuccess,
  getSpecialOfferItemSuccess,
} = slice.actions

export default slice.reducer
