// material
import { Container, Tab } from '@mui/material'
// hooks
import { useEffect, useState } from 'react'
import useSettings from '../../hooks/useSettings'
//store
import { getPageListThunk } from '../../redux/thunks/structure'
// components
import Page from '../../components/Page'
import { useDispatch } from '../../redux/store'
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import AboutSeo from './AboutSeo'
import AboutCareer from './AboutCareer'
import AboutHistory from './AboutHistory'
import AboutGoals from './AboutGoals'

export default function AboutPage() {
  const { themeStretch } = useSettings()
  const dispatch = useDispatch()

  const [currentTab, setCurrentTab] = useState<string | null>('1')

  useEffect(() => {
    dispatch(getPageListThunk())
  }, [dispatch])

  const handleChangeTab = (value: string | null) => {
    setCurrentTab(value)
  }

  return (
    <Page title="О компании">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs heading="О компании" links={[]} />

        <TabContext value={currentTab as string}>
          <TabList onChange={(e, value) => handleChangeTab(value)}>
            <Tab label="Наша история" value="1" />
            <Tab label="Цели" value="2" />
            <Tab label="Карьера" value="3" />
            <Tab label="SEO" value="4" />
          </TabList>
          <TabPanel value="1">
            <AboutHistory />
          </TabPanel>
          <TabPanel value="2">
            <AboutGoals />
          </TabPanel>
          <TabPanel value="3">
            <AboutCareer />
          </TabPanel>
          <TabPanel value="4">
            <AboutSeo />
          </TabPanel>
        </TabContext>
      </Container>
    </Page>
  )
}
