import { useEffect, useState } from 'react'
// redux
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Tab } from '@mui/material'
import { useSnackbar } from 'notistack'
import { RootState, useDispatch, useSelector } from '../../redux/store'
import {
  changeActiveServiceThunk,
  deleteServiceThunk,
  getServiceListThunk,
  updateServiceSortThunk,
} from '../../redux/thunks/services'
import { PATH_DASHBOARD } from '../../routes/paths'
import DnDTable from '../dndtable/DnDTable'
import { updateServiceSortOrderNew } from 'redux/slices/services'

export default function ServicesTable() {
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const [isReady, setIsReady] = useState(false)
  const { serviceList } = useSelector((state: RootState) => state.services)

  useEffect(() => {
    dispatch(getServiceListThunk())
    setIsReady(true)
  }, [dispatch])

  const handleOnDelete = async (id: number) => {
    const result = await dispatch(deleteServiceThunk('' + id))
    if (result) {
      enqueueSnackbar('Услуга удалена', { variant: 'success' })
    } else {
      enqueueSnackbar('Ошибка удаления услуги', { variant: 'error' })
    }
  }

  const handleOnChangeActive = async (id: number, checked: boolean) => {
    const result = await dispatch(changeActiveServiceThunk(id, checked))
    if (result) {
      enqueueSnackbar('Активность услуги изменена', { variant: 'success' })
    } else {
      enqueueSnackbar(
        'Ошибка выполнения запроса на изменение активности услуги',
        { variant: 'error' },
      )
    }
  }

  const handleOnChangeSort = async ({
    sortOrder,
    type,
  }: {
    sortOrder: number[]
    type: 'metal' | 'service' | 'customers'
  }) => {
    dispatch(updateServiceSortOrderNew({ sort: sortOrder, type }))
    const result = await dispatch(updateServiceSortThunk({ sortOrder, type }))
    if (result) {
      enqueueSnackbar('Порядок сохранен', { variant: 'success' })
    } else {
      enqueueSnackbar('123Ошибка выполнения запроса на сохранение порядка', {
        variant: 'error',
      })
    }
  }

  const [currentTab, setCurrentTab] = useState<string | null>('1')

  const handleChangeTab = (value: string | null) => {
    setCurrentTab(value)
  }

  return (
    <>
      {isReady && (
        <TabContext value={currentTab as string}>
          <TabList onChange={(e, value) => handleChangeTab(value)}>
            <Tab label="Металлообработка" value="1" />
            <Tab label="Покупателям" value="2" />
            <Tab label="Сервис" value="3" />
          </TabList>
          <TabPanel value="1">
            <DnDTable
              list={serviceList.metal.services}
              showRegions={true}
              handleOnDelete={handleOnDelete}
              handleOnChangeActive={handleOnChangeActive}
              handleOnChangeSort={num =>
                handleOnChangeSort({ sortOrder: num, type: 'metal' })
              }
              itemPath={PATH_DASHBOARD.services.root}
            />
          </TabPanel>
          <TabPanel value="2">
            <DnDTable
              list={serviceList.customers.services}
              showRegions={true}
              handleOnDelete={handleOnDelete}
              handleOnChangeActive={handleOnChangeActive}
              handleOnChangeSort={num =>
                handleOnChangeSort({ sortOrder: num, type: 'customers' })
              }
              itemPath={PATH_DASHBOARD.services.root}
            />
          </TabPanel>
          <TabPanel value="3">
            <DnDTable
              list={serviceList.service.services}
              showRegions={true}
              handleOnDelete={handleOnDelete}
              handleOnChangeActive={handleOnChangeActive}
              handleOnChangeSort={num =>
                handleOnChangeSort({ sortOrder: num, type: 'service' })
              }
              itemPath={PATH_DASHBOARD.services.root}
            />
          </TabPanel>
        </TabContext>
      )}
    </>
  )
}
