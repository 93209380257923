import { createSlice } from '@reduxjs/toolkit'
import { ReviewsItem } from '../../@types/reviews'

// ----------------------------------------------------------------------

type StateProp = {
  isLoading: boolean
  error: boolean
  reviewsList: ReviewsItem[]
  currentReviewsItem?: ReviewsItem
}

const initialState: StateProp = {
  isLoading: false,
  error: false,
  reviewsList: [],
}

const slice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    getReviewsListSuccess(state, action) {
      state.isLoading = false
      state.reviewsList = action.payload
    },

    getReviewsItemSuccess(state, action) {
      state.isLoading = false
      state.currentReviewsItem = action.payload
    },

    changeReviewsItemActiveStatus(state, action) {
      const list = state.reviewsList.map(reviewsItem => {
        const active =
          reviewsItem.id !== action.payload
            ? reviewsItem.active
            : reviewsItem.active
            ? false
            : true
        return {
          ...reviewsItem,
          active,
        }
      })
      state.reviewsList = list
    },

    deleteReviewsItem(state, action) {
      const list = state.reviewsList.filter(
        reviewsItem => reviewsItem.id !== action.payload,
      )
      state.reviewsList = list
    },

    updateReviewSortOrder(state, action) {
      const sort: number[] = action.payload
      const list: ReviewsItem[] = []
      sort.forEach(value => {
        const item = state.reviewsList.find(item => item.id === value)
        if (item !== undefined) list.push(item)
      })
      state.reviewsList = list
    },
  },
})

export const {
  startLoading,
  hasError,
  getReviewsListSuccess,
  getReviewsItemSuccess,
  changeReviewsItemActiveStatus,
  deleteReviewsItem,
  updateReviewSortOrder,
} = slice.actions

export default slice.reducer
