import { createSlice } from '@reduxjs/toolkit'
import { FullNewsItem, NewsItem, Category } from '../../@types/news'

// ----------------------------------------------------------------------

type NewsState = {
  isLoading: boolean
  error: boolean
  newsList: NewsItem[]
  currentNewsItem?: FullNewsItem
  categoryList: Category[]
  currentCategory?: Category
}

const initialState: NewsState = {
  isLoading: false,
  error: false,
  newsList: [],
  categoryList: [],
}

const slice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    getNewsListSuccess(state, action) {
      state.isLoading = false
      state.newsList = action.payload
    },

    getNewsItemSuccess(state, action) {
      state.isLoading = false
      state.currentNewsItem = action.payload
    },

    changeNewsItemActiveStatus(state, action) {
      const list = state.newsList.map(newsItem => {
        const active =
          newsItem.id !== +action.payload
            ? newsItem.active
            : newsItem.active
            ? 0
            : 1
        return {
          ...newsItem,
          active,
        }
      })
      state.newsList = list
    },

    changeNewsItemShowStatus(state, action) {
      const list = state.newsList.map(newsItem => {
        const showOnMain =
          newsItem.id !== +action.payload
            ? newsItem.showOnMain
            : newsItem.showOnMain
            ? 0
            : 1
        return {
          ...newsItem,
          showOnMain,
        }
      })
      state.newsList = list
    },

    deleteNewsItem(state, action) {
      const list = state.newsList.filter(
        newsItem => newsItem.id !== +action.payload,
      )
      state.newsList = list
    },

    getCategoryListSuccess(state, action) {
      state.isLoading = false
      state.categoryList = action.payload
    },

    getCategorySuccess(state, action) {
      state.isLoading = false
      state.currentCategory = action.payload
    },

    changeCategoryActiveStatus(state, action) {
      const list = state.categoryList.map(category => {
        const active =
          category.id !== +action.payload
            ? category.active
            : category.active
            ? 0
            : 1
        return {
          ...category,
          active,
        }
      })
      state.categoryList = list
    },

    deleteCategory(state, action) {
      const list = state.categoryList.filter(
        category => category.id !== +action.payload,
      )
      state.categoryList = list
    },
  },
})

export const {
  startLoading,
  hasError,
  getNewsListSuccess,
  getNewsItemSuccess,
  changeNewsItemActiveStatus,
  changeNewsItemShowStatus,
  deleteNewsItem,
  getCategoryListSuccess,
  getCategorySuccess,
  changeCategoryActiveStatus,
  deleteCategory,
} = slice.actions

export default slice.reducer
