import { Button, Card, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useState } from 'react'
import { Icon } from '@iconify/react'
import plusFill from '@iconify/icons-eva/plus-fill'
import DeleteIcon from '@mui/icons-material/Delete'

interface Factoid {
  id?: number
  title: string
  description: string
}

export default function Factoids({
  changeFactoids,
  factoids,
}: {
  changeFactoids: (val: Factoid[]) => void
  factoids: Factoid[]
}) {
  const [slides, setSlides] = useState<Factoid[]>([])

  const addCase = () => {
    setSlides(prev => [
      ...prev,
      {
        title: '',
        description: '',
      },
    ])
  }

  const removeCase = (index: number) => {
    setSlides(prev => {
      if (prev.length <= 1) {
        return []
      }
      return [...prev.slice(0, index), ...prev.slice(index + 1, prev.length)]
    })
  }

  const changeCase = (index: number, field: string, value: unknown) => {
    setSlides(prev =>
      prev.map((item: any, idx) => {
        if (idx === index) {
          return {
            ...item,
            [field]: value,
          }
        }
        return item
      }),
    )
  }

  useEffect(() => {
    setSlides(factoids)
  }, [])

  useEffect(() => {
    console.log(slides)
    changeFactoids(slides)
  }, [slides])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5, mt: 2 }}>
      <Button
        sx={{ width: 'fit-content' }}
        variant="contained"
        startIcon={<Icon icon={plusFill} />}
        onClick={addCase}
        disabled={slides.length >= 4}
      >
        Добавить фактоид
      </Button>

      {slides.map((item, index) => (
        <Card sx={{ p: 3 }} key={item.id ?? '-' + index}>
          <Box
            sx={{
              display: 'flex',
              gap: 10,
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '65%',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <TextField
                label="Заголовок"
                value={item.title}
                onChange={e => changeCase(index, 'title', e.target.value)}
              ></TextField>
              <TextField
                label="Описание"
                value={item.description}
                onChange={e => changeCase(index, 'description', e.target.value)}
              ></TextField>
            </Box>
            <div onClick={() => removeCase(index)}>
              <DeleteIcon sx={{ cursor: 'pointer' }} />
            </div>
          </Box>
        </Card>
      ))}
    </Box>
  )
}
