import { AppThunk } from '../store'
import axios from '../../utils/axios'
import {
  hasError,
  changeItemNew,
  getItemNewListSuccess,
  getDemandItemsSuccess,
  getSpecialOfferItemSuccess,
} from 'redux/slices/newCatalog'

export const getNewItemThunk =
  (
    page: number,
    perPage: string,
    regionId?: number | string,
    categoryId?: number | string,
    groupId?: number | string,
    search?: unknown,
  ): AppThunk =>
  async dispatch => {
    try {
      const response = await axios.get(
        `catalog/items?page=${page}&per_page=${perPage}&category_id=${categoryId}&region_id=${regionId}&group_id=${groupId}&search=${search}`,
      )
      dispatch(getItemNewListSuccess(response.data))
    } catch (error) {
      dispatch(hasError(error))
    }
  }

export const getDemandItemsThunk =
  (regionId: number | string): AppThunk =>
  async dispatch => {
    try {
      const response = await axios.get(
        `catalog/items/get-demand?region_id=${regionId}`,
      )
      dispatch(getDemandItemsSuccess(response.data))
    } catch (error) {
      dispatch(hasError(error))
    }
  }

export const getSpecialOfferItemsThunk =
  (regionId: number | string): AppThunk =>
  async dispatch => {
    try {
      const response = await axios.get(
        `catalog/items/get-special-offer?region_id=${regionId}`,
      )
      dispatch(getSpecialOfferItemSuccess(response.data))
    } catch (error) {
      dispatch(hasError(error))
    }
  }

export const patchItemNew =
  (itemId: number | string, val: boolean): AppThunk =>
  async dispatch => {
    try {
      await axios.post(`catalog/items/${itemId}/new`, {
        new: val,
      })
      dispatch(changeItemNew({ itemId, val }))
    } catch (error) {
      dispatch(hasError(error))
    }
  }
