import { createSlice } from '@reduxjs/toolkit'
import { BannersItem, FullBannersItem } from '../../@types/banners'

// ----------------------------------------------------------------------

type BannersState = {
  isLoading: boolean
  error: boolean
  bannersList: BannersItem[]
  currentBannersItem?: FullBannersItem
}

const initialState: BannersState = {
  isLoading: false,
  error: false,
  bannersList: [],
}

const slice = createSlice({
  name: 'banners',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    getBannersListSuccess(state, action) {
      state.isLoading = false
      state.bannersList = action.payload
    },

    getBannersItemSuccess(state, action) {
      state.isLoading = false
      state.currentBannersItem = action.payload
    },

    changeBannersItemActiveStatus(state, action) {
      const list = state.bannersList.map(item => {
        const active =
          item.id !== action.payload ? item.active : item.active ? 0 : 1
        return {
          ...item,
          active,
        }
      })
      state.bannersList = list
    },

    deleteBannersItem(state, action) {
      const list = state.bannersList.filter(item => item.id !== action.payload)
      state.bannersList = list
    },

    updateBannersSortOrder(state, action) {
      const sort: number[] = action.payload
      const list: BannersItem[] = []
      sort.forEach(value => {
        const item = state.bannersList.find(item => item.id === value)
        if (item !== undefined) list.push(item)
      })
      state.bannersList = list
    },
  },
})

export const {
  startLoading,
  hasError,
  getBannersListSuccess,
  getBannersItemSuccess,
  changeBannersItemActiveStatus,
  deleteBannersItem,
  updateBannersSortOrder,
} = slice.actions

export default slice.reducer
