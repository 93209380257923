import {
  Button,
  Card,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { useCallback, useEffect, useState } from 'react'
import { Icon } from '@iconify/react'
import plusFill from '@iconify/icons-eva/plus-fill'
import { UploadSingleFile } from 'components/upload'
import { dispatch } from 'redux/store'
import { uploadFileThunk } from 'redux/thunks/files'
import { useSnackbar } from 'notistack'
import axios, { baseUrl } from 'utils/axios'
import { LoadingButton } from '@mui/lab'
import TinyMCEEditorNew from 'components/editor/tinymce/new'
import DeleteIcon from '@mui/icons-material/Delete'

interface Slide {
  title: string
  description: string
  image: localFile | null
  active: boolean
  position: number
}

interface localFile extends File {
  fileName: string
  file: string
}

export default function AboutGoals() {
  const [slides, setSlides] = useState<Slide[]>([])
  const { enqueueSnackbar } = useSnackbar()

  const handleDropFile = useCallback(async (acceptedFiles, caseIndex) => {
    const file = acceptedFiles[0]
    if (file) {
      const loadedFileName = await dispatch(uploadFileThunk(file))
      if (loadedFileName) {
        setSlides(prev =>
          prev.map((item, idx) => {
            if (idx === caseIndex) {
              return {
                ...item,
                image: {
                  ...file,
                  fileName: loadedFileName,
                  preview: URL.createObjectURL(file),
                },
              }
            }
            return item
          }),
        )
      } else {
        enqueueSnackbar('Ошибка загрузки файла', { variant: 'error' })
      }
    }
  }, [])

  const addCase = () => {
    setSlides(prev => [
      ...prev,
      {
        title: '',
        description: '',
        image: null,
        active: false,
        position: 1,
      },
    ])
  }

  const removeGoal = (index: number) => {
    setSlides(prev => {
      if (prev.length <= 1) {
        return []
      }
      return [...prev.slice(0, index), ...prev.slice(index + 1, prev.length)]
    })
  }

  const changeCase = (index: number, field: string, value: unknown) => {
    setSlides(prev =>
      prev.map((item: any, idx) => {
        if (idx === index) {
          return {
            ...item,
            [field]: value,
          }
        }
        return item
      }),
    )
  }

  const getGoals = async () => {
    const data = await axios.get('/strategic-goals')
    setSlides(
      data.data.map((item: Slide) => {
        return {
          ...item,
          image: {
            ...(item.image as localFile),
            fileName: (item.image as localFile).file,
            preview: `${baseUrl}/files/strategicGoals/${
              (item.image as localFile).file
            }`,
          },
        }
      }),
    )
  }

  const putGoals = async () => {
    try {
      const data = await axios.put('/strategic-goals', {
        strategicGoals: slides.map((item: Slide) => {
          return {
            ...item,
            image: (item.image as localFile).fileName,
          }
        }),
      })
      setSlides(
        data.data.map((item: Slide) => {
          return {
            ...item,
            image: {
              ...(item.image as localFile),
              fileName: (item.image as localFile).file,
              preview: `${baseUrl}/files/strategicGoals/${
                (item.image as localFile).file
              }`,
            },
          }
        }),
      )
      enqueueSnackbar('Сохранено', { variant: 'success' })
    } catch (err) {
      enqueueSnackbar('При сохранении возникла ошибка', { variant: 'error' })
    }
  }

  useEffect(() => {
    getGoals()
  }, [])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5, mt: 2 }}>
      <Button
        sx={{ width: 'fit-content' }}
        variant="contained"
        startIcon={<Icon icon={plusFill} />}
        onClick={addCase}
      >
        Добавить цель
      </Button>

      {slides.map((item, index) => (
        <Card sx={{ p: 3 }} key={index}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <FormControlLabel
              labelPlacement="start"
              control={
                <Switch
                  checked={!!item.active}
                  onChange={e => changeCase(index, 'active', !!!item.active)}
                />
              }
              label={
                <>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Активность
                  </Typography>
                </>
              }
              sx={{
                mx: 0,
                mb: 3,
                width: 'fit-content',
                justifyContent: 'space-between',
              }}
            />
            <div onClick={() => removeGoal(index)}>
              <DeleteIcon sx={{ cursor: 'pointer' }} />
            </div>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              mt: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 10,
                mt: 2,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: '65%',
                  flexDirection: 'column',
                  gap: 2,
                  mt: 2,
                }}
              >
                <TextField
                  label="Название"
                  value={item.title}
                  onChange={e => changeCase(index, 'title', e.target.value)}
                ></TextField>
                Описание:
                <TinyMCEEditorNew
                  value={item.description}
                  onChange={val => changeCase(index, 'description', val)}
                />
              </Box>
              <UploadSingleFile
                sx={{ width: '30%' }}
                file={item.image}
                onDrop={acceptedFiles => handleDropFile(acceptedFiles, index)}
              />
            </Box>
          </Box>
        </Card>
      ))}
      {slides.length > 0 && (
        <LoadingButton
          sx={{ width: 'fit-content' }}
          variant="contained"
          onClick={putGoals}
        >
          Сохранить
        </LoadingButton>
      )}
    </Box>
  )
}
