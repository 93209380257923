import searchFill from '@iconify/icons-eva/search-fill'
import { Icon } from '@iconify/react'
import ClearIcon from '@mui/icons-material/Clear'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Card,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import Scrollbar from 'components/Scrollbar'
import { TableControl } from 'components/TableControl'
import { SearchStyle } from 'components/_dashboard/user/list/UserListToolbar'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { RootState } from 'redux/store'
import { getRegionListThunk } from 'redux/thunks/contact'
import {
  getDemandItemsThunk,
  getNewItemThunk,
  getSpecialOfferItemsThunk,
  patchItemNew,
} from 'redux/thunks/newCatalog'
import { getCategoryThunk, getGrupThunk } from 'redux/thunks/tag'
import { PATH_DASHBOARD } from 'routes/paths'
import axios from 'utils/axios'

type FormValuesProps = {
  id: string
  title: string
  category_id: string
  group_id: string
  region_id: string
  active: boolean
  metaH1: string
  metaTitle: string
  metaKeywords: string
  metaDescription: string
  slug: string
}

type Props = {
  onSave: () => void
}

const MainProducts = () => {
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const TABLE_HEAD = [
    { id: 'offer', label: 'Спецпредложение' },
    { id: 'demand', label: 'Пользуются спросом' },
    { id: 'new', label: 'Новинка' },
    { id: 'id', label: 'ID' },
    { id: 'name', label: 'Название' },
  ]

  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState('10')
  const [filterName, setFilterName] = useState('')

  const [selectedItems, setSelectedItems] = useState<Set<number>>(new Set())

  const { categoryList, grupList } = useSelector(
    (state: RootState) => state.tag,
  )

  const { itemNewList, demandList, specialOfferItems, meta } = useSelector(
    (state: RootState) => state.newCatalog,
  )

  const [localDemandList, setLocalDemandList] = useState<
    { id: number; stockId: number; price: number }[]
  >([])
  const [localOfferList, setLocalOfferList] = useState<
    { id: number; stockId: number; price: number }[]
  >([])

  const inDemandList = (id: number, stockId: number, price: number) => {
    const found =
      localDemandList.length &&
      localDemandList.find(
        item =>
          item.id === id && item.stockId === stockId && item.price === price,
      )
    return !!found
  }

  const inSpecialOfferList = (id: number, stockId: number, price: number) => {
    const found =
      localOfferList.length &&
      localOfferList.find(
        item =>
          item.id === id && item.stockId === stockId && item.price === price,
      )
    return !!found
  }

  const checkDemandList = (
    itemId: number,
    stock: number,
    price: number,
    checked: boolean,
  ) => {
    if (checked) {
      setLocalDemandList(prev => [
        ...prev,
        {
          id: itemId,
          stockId: stock,
          price: price,
        },
      ])
    } else {
      setLocalDemandList(prev => prev.filter(item => item.id !== itemId))
    }
  }

  const checkOfferList = (
    itemId: number,
    stock: number,
    price: number,
    checked: boolean,
  ) => {
    if (checked) {
      setLocalOfferList(prev => [
        ...prev,
        {
          id: itemId,
          stockId: stock,
          price: price,
        },
      ])
    } else {
      setLocalOfferList(prev => prev.filter(item => item.id !== itemId))
    }
  }

  const { regionList } = useSelector((state: RootState) => state.contact)

  const formik = useFormik<FormValuesProps>({
    initialValues: {
      id: '',
      title: '',
      category_id: '',
      group_id: '',
      region_id: '',
      active: true,
      metaH1: '',
      metaTitle: '',
      metaKeywords: '',
      metaDescription: '',
      slug: '',
    },

    onSubmit: async values => {
      if (values.category_id === '') {
        enqueueSnackbar('Необходимо выбрать хотя бы одну категорию', {
          variant: 'error',
        })
      } else {
        if (!Array.from(selectedItems).length) {
          enqueueSnackbar('Необходимо выбрать хотя бы однин товар', {
            variant: 'error',
          })
        } else {
          console.log('submit')
        }
      }
    },
  })

  const getItems = () => {
    if (values.group_id) {
      dispatch(
        getNewItemThunk(
          page,
          rowsPerPage,
          values.region_id,
          '',
          values.group_id,
          filterName,
        ),
      )
    } else {
      dispatch(
        getNewItemThunk(
          page,
          rowsPerPage,
          values.region_id,
          values.category_id,
          '',
          filterName,
        ),
      )
    }
  }

  const getItemsDemand = () => {
    dispatch(getDemandItemsThunk(values.region_id))
  }

  const getSpecialOffer = () => {
    dispatch(getSpecialOfferItemsThunk(values.region_id))
  }

  const handleChange = (event: SelectChangeEvent) => {
    setRowsPerPage(event.target.value as string)
    setPage(1)
  }

  const handleChangeCategory = (e: SelectChangeEvent<any>) => {
    setFieldValue('category_id', e.target.value)
    setFieldValue('group_id', '')
    setPage(1)

    setSelectedItems(new Set<number>())
  }

  const handleChangeGroup = (e: SelectChangeEvent<any>) => {
    setFieldValue('group_id', e.target.value)
    setPage(1)

    setSelectedItems(new Set<number>())
  }

  const handleFilterByName = (filterName: string) => {
    setFilterName(filterName)
  }

  const { values, getFieldProps, setFieldValue } = formik

  useEffect(() => {
    const timer = setTimeout(() => {
      values.region_id && getItems()
      values.region_id && getItemsDemand()
      values.region_id && getSpecialOffer()
    }, 100)

    return () => clearTimeout(timer)
  }, [
    values.category_id,
    values.group_id,
    values.region_id,
    filterName,
    rowsPerPage,
    page,
  ])

  useEffect(() => {
    setLocalDemandList(demandList)
  }, [demandList])

  useEffect(() => {
    setLocalOfferList(specialOfferItems)
  }, [demandList])

  useEffect(() => {
    dispatch(getGrupThunk(values.category_id))
  }, [dispatch, values.category_id])

  useEffect(() => {
    setPage(1)
  }, [values.group_id])

  useEffect(() => {
    dispatch(getRegionListThunk())
    dispatch(getCategoryThunk())
  }, [dispatch])

  const saveChanges = () => {
    try {
      saveDemand()
      saveOffer()
      enqueueSnackbar('Сохранено', { variant: 'success' })
    } catch (err) {
      enqueueSnackbar('При сохранении возникла ошибка', { variant: 'error' })
    }
  }

  const saveDemand = async () => {
    await axios.patch('/catalog/items/demand', {
      items: localDemandList.map(item => {
        return {
          item_id: item.id,
          stock_id: item.stockId,
        }
      }),
      region_id: values.region_id,
    })
  }

  const saveOffer = async () => {
    await axios.patch('/catalog/items/special-offer', {
      items: localOfferList.map(item => {
        return {
          item_id: item.id,
          stock_id: item.stockId,
          region_id: values.region_id,
        }
      }),
      region_id: values.region_id,
    })
  }

  const changeItem = (itemId: number, val: boolean) => {
    dispatch(patchItemNew(itemId, val))
  }

  return (
    <Card sx={{ p: 3, display: 'flex', flexDirection: 'column' }}>
      {values.region_id && (
        <LoadingButton
          sx={{ width: 'fit-content', alignSelf: 'flex-end' }}
          variant="contained"
          onClick={saveChanges}
        >
          Сохранить
        </LoadingButton>
      )}
      <Grid container spacing={3} sx={{ mt: 3 }}>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel>Регион</InputLabel>
            <Select
              {...getFieldProps('region_id')}
              label="Регион"
              IconComponent={
                values.region_id === ''
                  ? undefined
                  : () => (
                      <IconButton
                        onClick={() => setFieldValue('region_id', '')}
                        aria-label="delete"
                      >
                        <ClearIcon />
                      </IconButton>
                    )
              }
            >
              {regionList.length &&
                regionList.map(region => (
                  <MenuItem key={region.id} value={region.id}>
                    {region.title}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel>Категория</InputLabel>
            <Select
              value={values.category_id}
              label="Категория"
              onChange={e => handleChangeCategory(e)}
              IconComponent={
                values.category_id === ''
                  ? undefined
                  : () => (
                      <IconButton
                        onClick={() => setFieldValue('category_id', '')}
                        aria-label="delete"
                      >
                        <ClearIcon />
                      </IconButton>
                    )
              }
            >
              {categoryList.map(cat => (
                <MenuItem key={cat.id} value={cat.id}>
                  {cat.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel>Группа</InputLabel>
            <Select
              value={values.group_id}
              onChange={e => handleChangeGroup(e)}
              label="Группа"
              IconComponent={
                values.group_id === ''
                  ? undefined
                  : () => (
                      <IconButton
                        onClick={() => setFieldValue('group_id', '')}
                        aria-label="delete"
                      >
                        <ClearIcon />
                      </IconButton>
                    )
              }
            >
              {grupList.length &&
                grupList.map(cat => (
                  <MenuItem key={cat.id} value={cat.id}>
                    {cat.title}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12}>
          <Card>
            <SearchStyle
              value={filterName}
              onChange={e => handleFilterByName(e.target.value)}
              placeholder="Поиск..."
              sx={{ m: 3 }}
              startAdornment={
                <InputAdornment position="start">
                  <Box
                    component={Icon}
                    icon={searchFill}
                    sx={{ color: 'text.disabled' }}
                  />
                </InputAdornment>
              }
            />
            {itemNewList.length ? (
              <>
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {TABLE_HEAD.map(headCell => (
                            <TableCell key={headCell.id} align="center">
                              {headCell.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {itemNewList.map((row, rowIdx) => {
                          const {
                            id,
                            titleFull,
                            stockId,
                            price,
                            nomenclature,
                          } = row

                          return (
                            <TableRow
                              hover
                              key={id + stockId + rowIdx}
                              tabIndex={-1}
                              role="checkbox"
                            >
                              <TableCell align="center">
                                <Checkbox
                                  checked={inSpecialOfferList(
                                    row.id,
                                    row.stockId,
                                    row.price,
                                  )}
                                  disabled={
                                    localOfferList.length >= 1 &&
                                    !inSpecialOfferList(
                                      row.id,
                                      row.stockId,
                                      row.price,
                                    )
                                  }
                                  onChange={e =>
                                    checkOfferList(
                                      row.id,
                                      row.stockId,
                                      row.price,
                                      e.target.checked,
                                    )
                                  }
                                />
                              </TableCell>

                              <TableCell align="center">
                                <Checkbox
                                  checked={inDemandList(
                                    row.id,
                                    row.stockId,
                                    row.price,
                                  )}
                                  onChange={e =>
                                    checkDemandList(
                                      row.id,
                                      row.stockId,
                                      row.price,
                                      e.target.checked,
                                    )
                                  }
                                />
                              </TableCell>

                              <TableCell align="center">
                                <Switch
                                  checked={row.new}
                                  onChange={e => changeItem(row.id, !row.new)}
                                />
                              </TableCell>

                              <TableCell align="center">
                                <Typography variant="subtitle2" noWrap>
                                  {id}
                                </Typography>
                              </TableCell>

                              <TableCell align="center">
                                <Link
                                  underline="none"
                                  style={{ color: 'black' }}
                                  component={RouterLink}
                                  to={`${PATH_DASHBOARD.catalog.root}/item/${id}/edit`}
                                >
                                  <Typography variant="subtitle2" noWrap>
                                    {nomenclature ? nomenclature : titleFull}
                                  </Typography>
                                </Link>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <TableControl
                  meta={meta}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  setPage={setPage}
                  handleChange={handleChange}
                />
              </>
            ) : (
              <>Товары не найдены</>
            )}
          </Card>
        </Grid>
      </Grid>
    </Card>
  )
}

export default MainProducts
